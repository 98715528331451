import { createRouter, createWebHistory } from 'vue-router'

import DashboardLayout from '../layouts/DashboardLayout.vue'
import publicRoutes from './publicRoutes'
import authRoutes from './authRoutes'

import Auth from '../store/utils/Auth'
import store from '../store'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      if (Auth.accessToken) {
        // check if they have the authorization to enter their desired route before routing them
        next()
      } else {
        // clear all data here since they aren't authenticated
        next('/login')
      }
    },
    children: [...authRoutes],
  },
  ...publicRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  if (to.meta.allowedRoles) {
    if (!Object.keys(store.state.users.currentUser).length) {
      // if there is no current user object in the app, they need to log in again
      // TODO: should pull in user data if they have a valid token instead of
      // forcing additional login to get user info
      next('/login')
    } else if (to.meta.allowedRoles.includes(store.state.users.currentUser?.role)) {
      // if they are in the list of allowed user roles, let them through
      next()
    } else {
      // store.commit(
      //   'setToastr',
      //   { title: 'Unauthorized', message: 'You don\'t have permission to access that route.' }
      // )
      next(false)
    }
  } else {
    next()
  }
})

export default router
