import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: () => ({
    users: [],
    currentUser: {},
    isAuthenticated: false,
    checkingAuth: false,
  }),
  mutations,
  actions,
}
