<template>
  <AuthScreenLayout>
    <div class="p-col-5">
      <p>CODE*</p>
    </div>
    <div class="p-col-7">
      <InputText type="text" v-model="value" />
    </div>
    <div class="p-col-7">
      <Button label="Submit" class="p-button-text underline-button" />
    </div>
  </AuthScreenLayout>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import AuthScreenLayout from '@/layouts/AuthScreenLayout.vue'

export default {
  components: {
    AuthScreenLayout,
    InputText,
    Button,
  },
}
</script>
