<template>
  <div
    class="login-page p-grid"
    style="height: 100vh; margin-right: 0px; margin-top: -10px; margin-left: -10px"
  >
    <div class="main-landing-background"></div>

    <div class="p-col-5 textured-background">
      <div class="login-header p-grid p-align-center p-justify-around p-mt-2">
        <img
          src="https://storage.googleapis.com/chefann-prod-v1/images/SCALE-Logo.png"
          class="login-logo"
          path
          stle="fill: #388637"
        />
        <h1>Assessment</h1>
      </div>

      <div class="login-box p-grid p-align-center">
        <slot></slot>
      </div>

      <!-- <div class="login-footer">
        <img src="../assets/footer-fruits.png" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>
