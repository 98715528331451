<template>
  <div class="create-assessment-page assessments-page p-grid p-m-5">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },
  methods: {
    ...mapMutations('assessments', ['clearAssessment']),
  },
  unmounted() {
    // clear assessment creator data when user navigates away
    this.clearAssessment()
  },
}
</script>
