import Registration from '../views/auth/Registration.vue'
import Login from '../views/auth/Login.vue'
import Forgot from '../views/auth/Forgot.vue'
import Verify from '../views/auth/Verify.vue'
import Reset from '../views/auth/Reset.vue'
import NewRegistration from '@/views/auth/NewRegistration'
// import Maintainance from '../views/Maintainance.vue'

const siteName = 'The Lunch Box'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: `${siteName} | Login`,
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
    meta: {
      title: `${siteName} | Forgot Password`,
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify,
    meta: {
      title: `${siteName} | Verify`,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      title: `${siteName} | Reset`,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Registration,
    meta: {
      title: `${siteName} | Registration`,
    },
  },
  {
    path: '/new-registration',
    name: 'new-registration',
    component: NewRegistration,
    meta: {
      title: `${siteName} | Registration`,
    },
  },
]
