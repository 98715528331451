<template>
  <Card class="question-card">
    <template #header>
      <h3 class="category-title p-mt-0">{{ currentQuestionDetails?.section_title }}</h3>
    </template>
    <template #subtitle>
      <h3 class="category-subtitle p-my-0">{{ currentQuestionDetails?.sub_title }}</h3>
      <hr />
    </template>
    <template #content>
      <p>
        <strong
          :class="{
            'rollup-question-container':
              currentQuestionDetails?.question?.get_type_display === 'Rollup',
          }"
        >
          {{ currentQuestionDetails?.question?.text }}
          <div
            v-if="
              currentQuestionDetails?.question?.get_type_display === 'Rollup' &&
              !currentQuestionDetails?.question?.rollup_config?.total_choice
            "
            class="rollup-total"
            :class="{
              'percent-format':
                currentQuestionDetails?.question?.rollup_config?.display_format === 'percent',
            }"
          >
            <InputText
              :modelValue="rollupTotal"
              disabled="disabled"
              type="number"
              :class="{
                'p-invalid': isRollupTotalInvalid(calculateRollupTotal()),
              }"
            />
            <span class="rollup-percent">{{ rollupPercent(calculateRollupTotal()) }}</span>
          </div>
        </strong>
      </p>
      <p v-html="currentQuestionDetails?.question?.description"></p>

      <div
        class="answer-container"
        :class="{
          comparison: currentQuestionDetails?.question?.get_type_display === 'Comparison',
        }"
      >
        <template v-for="(choice, i) in currentQuestionDetails?.answers" :key="choice.id">
          <template
            v-if="currentQuestionDetails?.question?.get_type_display === 'Multiple Choice'"
          >
            <span class="answer-wrapper">
              <RadioButton
                :name="`${choice.id}-answer`"
                :value="choice"
                :modelValue="radioAnswer(choice)"
                @click="(e) => updateRadioAnswer(e, choice)"
                :class="{ 'p-invalid': isFieldInvalid(currentQuestionDetails?.question?.id) }"
              />
              <!-- v-model="radioAnswer" -->
              <label :for="`${choice.id}-answer`" @click="(e) => updateRadioAnswer(e, choice)">{{
                choice.text
              }}</label>
            </span>
          </template>
          <template
            v-if="currentQuestionDetails?.question?.get_type_display === 'Multiple Select'"
          >
            <span class="answer-wrapper">
              <Checkbox
                :modelValue="mcAnswer(choice, answer)"
                :binary="true"
                @change="(e) => setCheckbox(choice)"
                name="check-answer"
                :class="[
                  'p-invalid' ? isFieldInvalid(currentQuestionDetails?.question?.id) : '',
                  'mr-2',
                ]"
              />
              <label :for="`${choice.id}-answer`" @click="(e) => setCheckbox(choice)">{{
                choice.text
              }}</label>
            </span>
          </template>
          <InputText
            v-else-if="currentQuestionDetails?.question?.get_type_display === 'Text'"
            :modelValue="answer.value"
            @input="(e) => $emit('update:answer', { ...choice, value: e.target.value })"
            type="text"
            :class="[
              'full-width-input',
              isFieldInvalid(currentQuestionDetails?.question?.id) ? 'p-invalid' : '',
            ]"
          />
          <template
            v-else-if="currentQuestionDetails?.question?.get_type_display === 'Dichotomous'"
          >
            <span class="answer-wrapper">
              <RadioButton
                :name="`${choice.id}-answer`"
                :value="choice"
                :modelValue="radioAnswer(choice)"
                @click="(e) => updateRadioAnswer(e, choice)"
                :class="{ 'p-invalid': isFieldInvalid(currentQuestionDetails?.question?.id) }"
              />
              <label
                :for="`question-${currentQuestionDetails?.question?.id}`"
                @click="(e) => updateRadioAnswer(e, choice)"
                >{{ choice.text }}</label
              >
            </span>
          </template>
          <InputText
            v-else-if="currentQuestionDetails?.question?.get_type_display === 'Numeric'"
            :value="parseInt(answer.value)"
            @input="(e) => $emit('update:answer', { ...choice, value: e.target.value })"
            type="number"
            class="full-width-input"
          />
          <template
            v-else-if="currentQuestionDetails?.question?.get_type_display === 'Slider Rating'"
          >
            <Slider
              v-model="sliderAnswer"
              @change="(value) => $emit('update:answer', { ...choice, value: value.toString() })"
              :min="1"
              :max="10"
              :class="{ 'p-invalid': isFieldInvalid(currentQuestionDetails?.question?.id) }"
              style="width: 50%"
            />
          </template>
          <template v-else-if="currentQuestionDetails?.question?.get_type_display === 'Rollup'">
            <span
              class="answer-wrapper rollup"
              :class="{
                'rollup-total':
                  currentQuestionDetails?.question?.rollup_config?.total_choice === choice.id,
                'percent-format':
                  currentQuestionDetails?.question?.rollup_config?.display_format === 'percent',
              }"
            >
              <label :for="`${choice.id}-answer`">{{ choice.text }}</label>
              <InputText
                :name="`${choice.id}-answer`"
                :modelValue="choiceAnswer(choice, answer)"
                @change="(e) => updateChoiceAnswer(e, choice)"
                type="number"
                :class="{
                  'p-invalid':
                    isFieldInvalid(currentQuestionDetails?.question?.id) ||
                    (currentQuestionDetails?.question?.rollup_config?.total_choice === choice.id &&
                      isRollupTotalInvalid(choiceAnswer(choice, answer))),
                }"
              />
              <span class="rollup-percent">{{ rollupPercent(choiceAnswer(choice, answer)) }}</span>
            </span>
          </template>
          <template
            v-else-if="currentQuestionDetails?.question?.get_type_display === 'Comparison'"
          >
            <div
              v-if="currentQuestionDetails?.question?.comparison_config?.operator && i === 1"
              class="comparison-operator"
              style="align-self: center"
            >
              <label :for="`${currentQuestionDetails?.question.id}-operator`">
                {{
                  getOperatorDisplay(currentQuestionDetails?.question?.comparison_config?.operator)
                }}
              </label>
            </div>

            <div>
              <label :for="`${choice.id}-answer`">{{ choice.text }}</label>
              <InputText
                :name="`${choice.id}-answer`"
                :modelValue="choiceAnswer(choice, answer)"
                @change="(e) => updateChoiceAnswer(e, choice)"
                type="number"
                class="comparison-input"
              />
            </div>

            <div
              v-if="currentQuestionDetails?.question?.comparison_config?.operator && i === 1"
              class="comparison-operator"
              style="align-self: center; font-weight: bold; gap: 0.5rem"
            >
              <span>=</span>
              <span>{{ calculateComparisonResult() }}</span>
            </div>
          </template>
          <template v-else-if="currentQuestionDetails?.question?.get_type_display === 'File'">
            <div
              v-if="currentQuestionDetails?.question_answer?.file && i === 0"
              style="display: flex; flex-direction: row; margin-bottom: 10px; align-items: center"
            >
              <p style="margin: 0 10px 0 0">Uploaded:</p>
              <p style="margin: 0 20px 0 0">
                {{ getFileName(currentQuestionDetails.question_answer.file) }}
              </p>
              <Button
                @click="downloadFile(currentQuestionDetails.question_answer.file)"
                label="Download"
                class="text-button-underline"
              />
            </div>
            <div class="answer-wrapper">
              <FileUpload
                accept="image/*,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                @select="fileUploader($event, answer)"
                :customUpload="true"
                mode="basic"
                name="file"
                :multiple="false"
                :maxFileSize="1000000"
              />
            </div>

            <div>
              <label>NOTES </label>
              <br />
              <small class="block"><em>Add any additional notes for your file.</em></small>
              <br />
              <InputText
                :modelValue="answer.value"
                @input="(e) => $emit('update:answer', { ...answer, value: e.target.value })"
                type="text"
                :class="[
                  'full-width-input',
                  isFieldInvalid(currentQuestionDetails?.question?.id) ? 'p-invalid' : '',
                ]"
              />
            </div>
          </template>
        </template>
      </div>

      <template v-if="showResponse">
        <hr class="p-my-5" />

        <h3>RESPONSE:</h3>

        <template
          v-if="
            ['Multiple Select', 'Rollup', 'Comparison', 'File'].includes(
              currentQuestionDetails?.question?.get_type_display,
            )
          "
        >
          <div v-for="(res, i) in responses" :key="res + i" v-html="res" />
        </template>

        <div v-else v-html="answer.selected_response" />

        <div v-if="notSelectedResponses?.length > 0">
          <hr class="p-my-5" />

          <div v-for="(res, i) in notSelectedResponses" :key="res + i" v-html="res" />
        </div>
      </template>
    </template>
  </Card>
</template>

<script>
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import FileUpload from 'primevue/fileupload'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Slider from '@vueform/slider'
import _ from 'lodash'

// Utils
import { calculateComparison, getFileName, getOperatorDisplay } from '@/utils/helpers'

export default {
  components: {
    Button,
    FileUpload,
    InputText,
    RadioButton,
    Slider,
    Card,
    Checkbox,
  },
  props: {
    currentQuestionDetails: {
      type: Object,
    },
    isFieldInvalid: {
      type: Function,
    },
    answer: {
      type: Object,
    },
    showResponse: {
      type: Boolean,
    },
    notSelectedResponses: {
      type: Array,
    },
  },
  emits: ['update:answer'],
  computed: {
    sliderAnswer: {
      get() {
        return parseInt(this.answer?.value) || 1
      },
      set(val) {
        this.$emit('update:answer', { value: val || 1 })
      },
    },
    rollupTotal: {
      get() {
        if (this.currentQuestionDetails?.question?.rollup_config?.fixed_total) {
          return this.currentQuestionDetails?.question?.rollup_config?.fixed_total
        }

        return this.calculateRollupTotal()
      },
      set(_) {
        // N/A
      },
    },
    responses() {
      if (
        ['Multiple Select', 'Rollup', 'Comparison'].includes(
          this.currentQuestionDetails?.question?.get_type_display,
        )
      ) {
        const answers = JSON.parse(this.answer?.value || '[]')
        return answers.map((answer) => answer.selected_response)
      } else if (this.currentQuestionDetails?.question?.get_type_display === 'File') {
        return this.currentQuestionDetails.answers.map((a) => a.selected_response)
      }
      return this.answer?.selected_response
    },
  },
  methods: {
    getOperatorDisplay,
    getFileName,
    downloadFile(file) {
      window.open(file, '_blank')
    },
    fileUploader(e, answer) {
      this.$emit('update:answer', { ...answer, file: e.files[0] })
    },
    radioAnswer(choice) {
      if (this.answer.question_choice && this.answer.question_choice === choice.id) {
        return choice
      }
      return this.answer
    },
    mcAnswer(choice, answer) {
      if (choice && answer.value) {
        const jsonAnswer = JSON.parse(answer.value)
        if (jsonAnswer) {
          if (Array.isArray(jsonAnswer)) {
            if (answer.value && jsonAnswer.findIndex((val) => val.id === choice.id) !== -1) {
              return true
            }
            return false
          } else {
            if (answer.value && answer.id === choice.id) {
              return true
            }
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    choiceAnswer(choice, answer) {
      if (!choice) {
        return null
      }

      const answers = answer.value ? JSON.parse(answer.value) : []
      const answerChoice = answers.find((a) => a.id === choice.id)
      const value = answerChoice?.value

      return value ? parseInt(value) : value
    },
    rollupPercent(value) {
      const total = this.rollupTotal

      if (!value || !total) {
        return ''
      }

      return Math.round((100 * value) / total)
    },
    calculateRollupTotal() {
      // NOTE: logic duplicated in TakeAssessment's rollupAnswersTotal
      if (!this.answer.value) {
        return ''
      }

      const answers = JSON.parse(this.answer.value)
      const total = answers.reduce((sum, a) => {
        if (this.currentQuestionDetails?.question?.rollup_config?.total_choice === a.id) {
          // Do not include total choice value in total of sub-choices
          return parseInt(sum || 0)
        } else {
          return parseInt(sum || 0) + parseInt(a.value || 0)
        }
      }, 0)

      return total
    },
    isRollupTotalInvalid(total) {
      const calculatedTotal = this.calculateRollupTotal()
      const fixedTotal = this.currentQuestionDetails?.question?.rollup_config?.fixed_total
      const totalChoiceId = this.currentQuestionDetails?.question?.rollup_config?.total_choice

      if (fixedTotal) {
        // Total should match the configured total from the rollup config
        return total !== fixedTotal
      } else if (totalChoiceId) {
        // Calculated total should match the user-given total in the specified total choice input
        const answers = this.answer.value ? JSON.parse(this.answer.value) : []
        const totalChoice = answers?.find((a) => a.id === totalChoiceId)
        return calculatedTotal !== parseInt(totalChoice?.value)
      } else if (
        this.currentQuestionDetails?.question?.rollup_config?.display_format === 'percent'
      ) {
        return total !== 100
      } else {
        // Auto-calculated count is automatically valid
        return false
      }
    },
    calculateComparisonResult() {
      const answers = JSON.parse(this.answer.value)

      // Only calculate the result if bold fields have been filled out
      if (
        _.filter(
          _.map(answers, (a) => a.value),
          (a) => a,
        ).length < 2
      ) {
        return 0
      }

      const operator = this.currentQuestionDetails?.question?.comparison_config?.operator
      const firstAnswer = answers[0]?.value
      const secondAnswer = answers[1]?.value

      return calculateComparison(operator, firstAnswer, secondAnswer)
    },
    updateRadioAnswer(e, choice) {
      if (this.answer === choice) {
        this.$emit('update:answer', {})
      } else {
        this.$emit('update:answer', choice)
      }
    },
    updateChoiceAnswer(e, choice) {
      // Get index of current choice in answer.value array
      const answers = this.answer.value ? JSON.parse(this.answer.value) : []
      const index = answers.findIndex((v) => {
        return v.id === choice.id
      })

      // Set new value for choice from input
      answers[index] = { ...choice, value: parseInt(e.target.value) }

      // Update answer.value with maintained array of all choices and their values
      this.$emit('update:answer', { value: JSON.stringify(answers) })
    },
    updateTextField(e, choice) {
      this.$emit('update:answer', { ...choice, value: e.target.value })
    },
    setCheckbox(choice) {
      // Had to use stringify and parse this.answer.value here because
      // this. $emit was stringifying nested objects and it was causing
      // strange problems

      let values = []

      if (this.answer.value) {
        const parsedAnswer = JSON.parse(this.answer.value)
        if (Array.isArray(parsedAnswer)) {
          const index = parsedAnswer.findIndex((val) => {
            return val.id === choice.id
          })
          if (index !== -1) {
            values = [...parsedAnswer]
            values.splice(index, 1)
          } else {
            values = [...parsedAnswer, choice]
          }
        } else {
          if (this.answer.id === choice.id) {
            values = []
          } else {
            values.push(this.answer)
            values.push(choice)
          }
        }
      } else {
        values.push(choice)
      }

      this.$emit('update:answer', { value: JSON.stringify(values) })
    },
  },
}
</script>
