import htmlToPdfmake from 'html-to-pdfmake'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import _ from 'lodash'
import dayjs from 'dayjs'

// Font imports
import { sourceSansProRegular } from '../assets/fonts/sourceSansProBase64.js'
import { sourceSansProLight } from '../assets/fonts/sourceSansProLightBase64.js'
import { sourceSansProBold } from '../assets/fonts/sourceSansProBoldBase64.js'
import { sourceSansProItalic } from '../assets/fonts/sourceSansProItalicBase64.js'
import { sourceSansProBoldItalic } from '../assets/fonts/sourceSansProBoldItalic64.js'
import { crimsonRegular } from '../assets/fonts/crimsonBase64.js'

// Image imports
import { footerImage } from '../assets/pdfImages/footerImage.js'
import { headerImage } from '../assets/pdfImages/headerImage.js'
import { headerBlockImage } from '../assets/pdfImages/headerBlockImage.js'

// Utils
import { calculateComparison, getFileName, getOperatorDisplay } from './helpers.js'

// Assign custom fonts to pdfMake's virtual file system
pdfMake.vfs = {
  ...pdfFonts.pdfMake.vfs,
  'SourceSansPro-Light.ttf': sourceSansProLight,
  'SourceSansPro-Regular.ttf': sourceSansProRegular,
  'SourceSansPro-Italic.ttf': sourceSansProItalic,
  'SourceSansPro-Bold.ttf': sourceSansProBold,
  'SourceSansPro-BoldItalics.ttf': sourceSansProBoldItalic,
  'CrimsonText-Regular.ttf': crimsonRegular,
}
pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
  SourceSansPro: {
    light: 'SourceSansPro-Light.ttf',
    normal: 'SourceSansPro-Regular.ttf',
    italics: 'SourceSansPro-Italic.ttf',
    bold: 'SourceSansPro-Bold.ttf',
    bolditalics: 'SourceSansPro-BoldItalics.ttf',
  },
  Crimson: {
    normal: 'CrimsonText-Regular.ttf',
    bold: 'CrimsonText-Regular.ttf',
  },
}

const mergeQuestionDetails = (section) => {
  // Check if there are question_responses that also have not_selected_responses for the same question
  // Group these together for display
  const mergedQuestionDetails = _(section.question_responses)
    .keyBy('id') // Create a dictionary of the 1st array using the id as the key
    .merge(_.keyBy(section.not_selected_responses, 'id')) // Create a dictionary of the 2nd array using the id as the key, replacing duplicates
    .values() // Convert dictionary to array
    .value()

  // Verify that all questions have the `not_selected_responses` property
  // If not, add it with an empty value
  _.forEach(mergedQuestionDetails, (q) => {
    if (!q.not_selected_responses) {
      q.not_selected_responses = ''
    }
  })
  return mergedQuestionDetails
}

const processResponseDisplay = (response, parser, responseDisplay) => {
  // Check to see if there is a <body> tag in the response, if there is then we need to display rich text
  const responseTag = parser.parseFromString(response, 'text/html').getElementsByTagName('body')

  // If a <body> tag is found, then we need to loop through the children, add the margin
  // and append the response to the responseDisplay
  if (responseTag && responseTag[0].children.length > 1) {
    _.forEach(responseTag[0].children, (child) => {
      child.style.marginLeft = 75
      responseDisplay = `${responseDisplay}${child.outerHTML}`
    })
  } else {
    // Otherwise, its just text and needs to be wrapped in a <p> tag before appending
    responseDisplay = `${responseDisplay}<p style="margin-left:35">${response}</p>`
  }

  return responseDisplay
}

const getResponsesToDisplay = (question, detailed = false, admin = false) => {
  const parser = new DOMParser()

  let responses = '<div>'
  let externalNotes = '<div>'
  let keyRecommendations = '<div>'
  let bestPractices = '<div>'
  let confidentialNotes = '<div>'

  _.forEach(question.answers, (answer) => {
    /** Configure Response Display */
    if (answer.selected_response !== '' && _.isArray(answer.selected_response)) {
      _.forEach(answer.selected_response, (r) => {
        responses = processResponseDisplay(r, parser, responses)
      })
    } else if (answer.selected_response !== '') {
      responses = processResponseDisplay(answer.selected_response, parser, responses)
    }

    if (answer.not_selected_responses && _.isArray(answer.not_selected_responses)) {
      _.forEach(answer.not_selected_responses, (r) => {
        responses = processResponseDisplay(r, parser, responses)
      })
    } else if (answer.not_selected_responses) {
      responses = processResponseDisplay(answer.not_selected_responses, parser, responses)
    }

    /** Configure Extra Fields Display (if applicable) */
    if (answer.external_notes) {
      externalNotes = `${externalNotes}<p style="margin-left:${detailed ? '55' : '25'}">${
        answer.external_notes
      }</p>`
    }
    if (answer.key_recommendations?.length > 0) {
      _.forEach(answer.key_recommendations, (r) => {
        const recommendation = `<p style="weight:semi-bold;margin-left:${
          detailed ? '55' : '25'
        }">${r.text}</p>`
        const actionItems = _.map(
          r.action_items,
          (a, i) => `<p style="margin-left:${detailed ? '75' : '55'}">${i + 1}. ${a.text}</p>`,
        )
        keyRecommendations = `${keyRecommendations}${recommendation}${actionItems}`
      })
    }
    if (answer.best_practices?.length > 0) {
      _.forEach(answer.best_practices, (b) => {
        bestPractices = `${bestPractices}<p style="margin-left:${detailed ? '55' : '25'}">${
          b.text
        }</p>`
      })
    }
    if (admin && answer.confidential_notes) {
      confidentialNotes = `${confidentialNotes}<p style="margin-left:${detailed ? '55' : '25'}">${
        answer.confidential_notes
      }</p>`
    } else {
      confidentialNotes = null
    }
  })

  return {
    responses,
    externalNotes,
    keyRecommendations,
    bestPractices,
    confidentialNotes,
  }
}

/**
 * Generate a section of the report based on the key provided
 */
const generateSection = (displays, key) => {
  const header = `<h6 style="margin-left:25"><strong>${_.startCase(key)}:</strong></h6>`
  if (_.some(displays, (d) => d[key] !== '')) {
    return `
      ${header}
      ${_.map(displays, (d) => d[key])}
    `
  }

  return key === 'responses'
    ? `
    ${header}
    ${p(`No ${_.lowerCase(key)} for this section.`)}
  `
    : ''
}

const configureResponseSection = (type, section, detailed = false, admin = false) => {
  // Merge the question_responses and not_selected_responses together
  const questionDetails = mergeQuestionDetails(section)

  // Group and check for all comparison questions. One or both of the choices
  // could have responses configured, and we need to display both fields for the detailed report.
  const comparisonDisplays = []
  if (section.question_responses.some((q) => q.type === 12)) {
    // Pull the pairs of comparison questions, formatting just one object for display
    const comparisonQuestions = _.filter(section.question_responses, (q) => q.type === 12)
    const groups = _.groupBy(comparisonQuestions, (q) => q.id)

    _.forEach(_.keys(groups), (k) => {
      const first = groups[k][0]
      const second = groups[k][1]

      // If either choice has a response configured, group them together
      const operator = getOperatorDisplay(first.operator)
      first.display_format = `${first.answers[0].choice}: <strong>${
        first.answers[0].value
      }</strong> ${operator} ${second.answers[0].choice}: <strong>${
        second.answers[0].value
      }</strong> = <strong>${calculateComparison(
        operator,
        first.answers[0].value,
        second.answers[0].value,
      )}</strong>`
      first.selected_response = _.filter(
        [first.answers[0].selected_response, second.answers[0].selected_response],
        (r) => r !== null && r !== '',
      )
      comparisonDisplays.push(first)
    })

    // Replace the first instance of the comparison question with the grouped display, and remove the second
    _.forEach(comparisonDisplays, (c) => {
      const pair = _.filter(questionDetails, (q) => q.id === c.id)
      const index = _.findIndex(questionDetails, (q) => q.id === c.id)
      questionDetails.splice(index, 1, c)

      // If both choices have a response configured, remove the second instance
      if (pair.length > 1) {
        const secondIndex = _.findLastIndex(questionDetails, (q) => q.id === c.id)
        questionDetails.splice(secondIndex, 1)
      }
    })
  }

  if (detailed) {
    return _.map(questionDetails, (question) => {
      const responsesToDisplay = getResponsesToDisplay(question, detailed, admin)

      let answerText = ''
      if (question.type === 3) {
        // Multiple Select
        answerText = _.map(question.answers, (a) => a.choice).join(', ')
      } else if (question.type === 11) {
        // Rollup Question
        answerText = `${question.choice} - ${question.value}${
          question.display_format === 'percent' ? '%' : ''
        }`
      } else if (question.type === 12) {
        // Comparison Question
        answerText = question.display_format
      } else {
        answerText = question.answers[0].choice || question.answers[0].value
      }

      // Close out the parent divs
      if (responsesToDisplay.responses !== '<div>') {
        responsesToDisplay.responses = `<p style="margin-left:55"><strong>Response:</strong>${responsesToDisplay.responses}</p></div>`
      } else if (responsesToDisplay.responses !== '<div>') {
        responsesToDisplay.responses = ''
      }

      if (responsesToDisplay.externalNotes !== '<div>') {
        responsesToDisplay.externalNotes = `<p style="margin-left:55"><strong>External Notes:</strong>${responsesToDisplay.externalNotes}</p></div>`
      } else {
        responsesToDisplay.externalNotes = ''
      }
      if (responsesToDisplay.keyRecommendations !== '<div>') {
        responsesToDisplay.keyRecommendations = `<p style="margin-left:55"><strong>Key Recommendations:</strong>${responsesToDisplay.keyRecommendations}</p></div>`
      } else {
        responsesToDisplay.keyRecommendations = ''
      }
      if (responsesToDisplay.bestPractices !== '<div>') {
        responsesToDisplay.bestPractices = `<p style="margin-left:55"><strong>Best Practices:</strong>${responsesToDisplay.bestPractices}</p></div>`
      } else {
        responsesToDisplay.bestPractices = ''
      }
      if (admin && responsesToDisplay.confidentialNotes !== null) {
        responsesToDisplay.confidentialNotes = `<p style="margin-left:55"><strong>Confidential Notes:</strong>${responsesToDisplay.confidentialNotes}</p></div>`
      } else {
        responsesToDisplay.confidentialNotes = ''
      }

      if (question.type !== 9) {
        return `
            ${p(`<strong>Q: ${question.text}</strong>`)}
            ${p(`<p style="margin-left:55"><strong>A:</strong> ${answerText}</p>`)}
            ${p(responsesToDisplay.responses)}
            ${type !== 'Self Assessment' && p(responsesToDisplay.externalNotes)}
            ${type !== 'Self Assessment' && p(responsesToDisplay.keyRecommendations)}
            ${type !== 'Self Assessment' && p(responsesToDisplay.bestPractices)}
            ${type !== 'Self Assessment' && admin && p(responsesToDisplay.confidentialNotes)}
            `
      } else {
        return `
            ${p(`<strong>Q: ${question.text}</strong>`)}
            ${p(`<p style="margin-left:55"><strong>A:</strong> ${getFileName(question.file)}</p>`)}
            ${
              question.value !== null && question.value !== ''
                ? p(`<strong>Notes:</strong> ${question.value}`)
                : ''
            }
            ${p(responsesToDisplay.responses)}
            ${type !== 'Self Assessment' && p(responsesToDisplay.externalNotes)}
            ${type !== 'Self Assessment' && p(responsesToDisplay.keyRecommendations)}
            ${type !== 'Self Assessment' && p(responsesToDisplay.bestPractices)}
            ${type !== 'Self Assessment' && admin && p(responsesToDisplay.confidentialNotes)}
            `
      }
    })
  }

  const displays = _.map(questionDetails, (question) => {
    const responsesToDisplay = getResponsesToDisplay(question, detailed)

    // Close out the parent divs
    if (responsesToDisplay.responses !== '<div>') {
      responsesToDisplay.responses = `<p style="margin-left:25">${responsesToDisplay.responses}</p></div>`
    } else {
      responsesToDisplay.responses = ''
    }

    if (responsesToDisplay.externalNotes !== '<div>') {
      responsesToDisplay.externalNotes = `<p style="margin-left:25">${responsesToDisplay.externalNotes}</p></div>`
    } else {
      responsesToDisplay.externalNotes = ''
    }
    if (responsesToDisplay.keyRecommendations !== '<div>') {
      responsesToDisplay.keyRecommendations = `<p style="margin-left:25">${responsesToDisplay.keyRecommendations}</p></div>`
    } else {
      responsesToDisplay.keyRecommendations = ''
    }
    if (responsesToDisplay.bestPractices !== '<div>') {
      responsesToDisplay.bestPractices = `<p style="margin-left:25">${responsesToDisplay.bestPractices}</p></div>`
    } else {
      responsesToDisplay.bestPractices = ''
    }

    return {
      responses: responsesToDisplay.responses,
      externalNotes: responsesToDisplay.externalNotes,
      keyRecommendations: responsesToDisplay.keyRecommendations,
      bestPractices: responsesToDisplay.bestPractices,
    }
  })

  return `
    ${generateSection(displays, 'responses')}
    ${generateSection(displays, 'externalNotes')}
    ${generateSection(displays, 'keyRecommendations')}
    ${generateSection(displays, 'bestPractices')}
  `
}

const percentageScore = (assessmentReport) => {
  return Math.round(
    (assessmentReport?.report?.report_data?.actual_score /
      assessmentReport?.report?.report_data?.total_score) *
      100,
  )
}

const completedDate = (assessmentReport) => {
  return dayjs(assessmentReport.report.report_data.completed_at).format('MM/DD/YYYY')
}

const fileDate = (assessmentReport) => {
  return dayjs(assessmentReport.report.report_data.updated_at).format('MM/DD/YYYY')
}

export const reportName = (assessmentReport, type, currentUser) => {
  return `SCALE - ${assessmentReport.assessment.name} ${type} - ${fileDate(assessmentReport)} ${
    currentUser.role === 'Superuser'
      ? assessmentReport.report.report_data.taken_by.user_profile.district.name
          .toString()
          .replace(',', ', ')
      : ''
  }`
}

const p = (content) => {
  return content ? `<p style="margin-left:25;">${content}</p>` : ''
}

const prioritizedSectionScoreResponse = (report, section) => {
  const sectionScore = report.report_data.section_scores.find(
    (s) => s.id === section.subsection_data.section,
  )
  const {
    actual_score: actual,
    total_score: total,
    score_threshold: threshold,
    score_response: response,
  } = sectionScore

  if (!total || !threshold || !response) {
    return ''
  }

  return threshold >= (100 * actual) / total ? response : ''
}

const sectionsHtml = (assessmentReport) => {
  const { subsections } = assessmentReport.report

  // Group all subsections by section so we can properly determine scoring
  const groupedSections = _.groupBy(subsections, (s) => s.subsection_data.section)

  const sectionsBlock = `<div>
          ${_.map(_.keys(groupedSections), (key) => {
            // Calculate the actual score for the section
            const sections = groupedSections[key]
            const actualScore = _.sumBy(sections, (s) => s.subsection_data.actual_score)

            // Generate section block by mapping through each subsection
            return _.map(sections, (section, i) => {
              const sectionBlock = `
                ${
                  sections[i - 1] &&
                  sections[i - 1].subsection_data.section_name ===
                    section.subsection_data.section_name
                    ? ''
                    : `<h5 class="sectionHeader"><strong>${
                        section.subsection_data.section_name
                      }</strong></h5>
                      <h5 class="score"><strong>Score: ${actualScore}</strong></h5>
                      ${p(section.subsection_data.section_description)}
                      ${p(prioritizedSectionScoreResponse(assessmentReport.report, section))}
                      `
                }
                <h6 class="subHeader"><strong>${
                  section.subsection_data.subsection_name
                }</strong></h6>
                ${p(section.subsection_data.subsection_description)}
                <div>
                  ${configureResponseSection(assessmentReport.get_type_display, section)}
                </div>
              `
              return sectionBlock
            })
          })}
        </div>`
  return sectionsBlock
}

const detailedSectionsHtml = (assessmentReport, admin = false) => {
  let { subsections } = assessmentReport.report

  // If there are files in this report, we need to see if there are corresponding response sections that will
  // be generated. If so, we need to display the file if it is an image.
  if (assessmentReport.report.report_data.files?.length > 0) {
    subsections = _.map(subsections, (s) => {
      const updatedQuestions = _.map(s.question_responses, (q) => {
        let updatedQuestion = { ...q }
        if (updatedQuestion.type === 9) {
          // Find the matching file object and insert it into the question
          const file = assessmentReport.report.report_data.files.find(
            (f) => f.question_id === q.id,
          )
          if (file) {
            updatedQuestion = { ...updatedQuestion, file: file.file }
          }
        }

        return updatedQuestion
      })

      return { ...s, question_responses: updatedQuestions }
    })
  }

  // Group all subsections by section so we can properly determine scoring
  const groupedSections = _.groupBy(subsections, (s) => s.subsection_data.section)

  const sectionsBlock = `<div>
          ${_.map(_.keys(groupedSections), (key) => {
            // Calculate the total and actual scores for the section
            const sections = groupedSections[key]
            const totalScore = _.sumBy(sections, (s) => s.subsection_data.total_score)
            const actualScore = _.sumBy(sections, (s) => s.subsection_data.actual_score)

            // Generate section block by mapping through each subsection
            return _.map(sections, (section, i) => {
              const sectionBlock = `
              ${
                sections[i - 1] &&
                sections[i - 1].subsection_data.section_name ===
                  section.subsection_data.section_name
                  ? ''
                  : `<h5 class="sectionHeader"><strong>${
                      section.subsection_data.section_name
                    }</strong></h5>
                    ${
                      totalScore === null || totalScore === 0
                        ? `<h5 class="score"><strong>Score: ${actualScore}</strong></h5>`
                        : `<h5 class="score"><strong>Score: ${actualScore}/${totalScore} (${Math.round(
                            (actualScore / totalScore) * 100,
                          )}%)</strong></h5>`
                    }
                    ${p(section.subsection_data.section_description)}
                    ${p(prioritizedSectionScoreResponse(assessmentReport.report, section))}
                    `
              }
              <h6 class="subHeader"><strong>${
                section.subsection_data.subsection_name
              }</strong></h6>
              ${p(section.subsection_data.subsection_description)}
              ${
                section.question_responses
                  ? p('<strong>Questions/Answers and Responses:</strong>')
                  : ''
              }
              <div>
                ${configureResponseSection(
                  assessmentReport.get_type_display,
                  section,
                  true,
                  admin,
                )}
              </div>
            `
              return sectionBlock
            })
          })}
        </div>`
  return sectionsBlock
}

const sectionScores = (report) => {
  const subsections = report.subsections
  const sectionScores = _.map(subsections, (section, i) => {
    const sectionName = section.subsection_data.section_name

    // Scores
    const {
      actual_score: actualScore,
      total_score: totalScore,
      score_threshold: threshold,
    } = report.report_data.section_scores.find((s) => s.id === section.subsection_data.section)

    let score = actualScore
    let prioritize = false
    if (totalScore !== 0 && totalScore !== null) {
      const scorePercent = (actualScore / totalScore) * 100

      score = `${Math.round(scorePercent)}%`
      prioritize = threshold >= scorePercent
    }

    const sectionScore = p(
      `${sectionName} - <strong>${score}</strong>${prioritize ? ' - HIGH PRIORITY' : ''}`,
    )
    return subsections[i - 1] && subsections[i - 1].subsection_data.section_name === sectionName
      ? ''
      : sectionScore
  })

  return `<div style="margin-top:0">${sectionScores}</div>`
}

export const reportPDF = (assessmentReport, type) => {
  const detailed = type.includes('Detailed')
  let title = ''
  if (type === 'Detailed') title = 'Detailed Report'
  if (type === 'Summary') title = 'Summary Report'
  if (type === 'Admin Detailed') title = 'Detailed Report (Admin)'

  const pdfHtml = htmlToPdfmake(
    `
        <div>
          <div class="textBlock">
            <h2 class="frontText">District Assessment - ${title}</h2>
            <h3 class="generated">Generated by the Chef Ann Foundation and the Scratch Cooking and Self Evaluation (SCALE) platform</h3>
            <h5 class="overallScore" style="font-weight:normal"><strong>Overall Score:</strong> ${
              assessmentReport?.report?.report_data?.total_score === 0
                ? assessmentReport?.report?.report_data?.actual_score
                : `${percentageScore(assessmentReport)}%`
            }</h5>
            <h6 class="district" style="font-weight:normal"><strong>District:</strong> ${assessmentReport.report.report_data.taken_by.user_profile.district.name
              ?.toString()
              .replace(',', ', ')}</h6>
            <h6 class="user" style="font-weight:normal"><strong>User:</strong> ${
              assessmentReport.report.report_data.taken_by.full_name
            }</h6>
            <h6 class="date" style="font-weight:normal"><strong>Date:</strong> ${completedDate(
              assessmentReport,
            )}</h6>
            <p class="footer" style="margin-left:25;margin-top:225">The Chef Ann Foundation is a 501c(3) non-profit dedicated to promoting whole-ingredient, scratch cooking in schools. Scratch cooking enables schools to serve the healthiest, tastiest meals so that kids are well-nourished and ready to learn. To date, we’ve reached more than 13,500 schools and 3.3 million kids with healthy school programming.</p>
            <h3 class="pdf-pagebreak-before">Intro</h3>
            <p class="introText" style="margin-left:25">Thank you for completing a SCALE Assessment! This report has been generated from your answers
              and inputs. Each question you answered is built into your score, and some sections will have
              feedback associated. Not every answer will generate feedback.
            </p>
            <p class="introText" style="margin-left:25">If you are working on behalf of a school district, we encourage you to take the assessment every
              year to see how your scores develop and change. Scores can be used to measure progress in your
              strategic plan, and the feedback can be used as a checklist or general direction for your team as
              part of a strategic plan.
            </p>
            <p class="introText" style="margin-left:25">If you have any questions, or would like additional support, please reach out to us at
              info@chefannfoundation.org. Thank you!
            </p>
            <h2 class="sectionHeader" style="margin-bottom:0">Section Scores</h2>
            ${sectionScores(assessmentReport.report).replaceAll(',', '')}
            ${
              detailed
                ? detailedSectionsHtml(assessmentReport, type.includes('Admin')).replaceAll(
                    ',',
                    '',
                  )
                : sectionsHtml(assessmentReport).replaceAll(',', '')
            }
          </div>
        </div>
        `,
    {
      imagesByReference: true,
      removeExtraBlanks: true,
      defaultStyles: { h5: { bold: false }, h6: { bold: false } },
    },
  )

  var pdfConfig = {
    content: [pdfHtml.content],
    defaultStyle: { font: 'SourceSansPro' },
    styles: {
      frontText: {
        marginTop: 170,
        color: 'green',
        font: 'SourceSansPro',
        bold: false,
        fontSize: 34,
      },
      generated: {
        marginTop: 50,
        color: '#326195',
        font: 'Crimson',
        bold: false,
        fontSize: 20,
      },
      overallScore: { marginTop: 25 },
      score: { color: '#326195' },
      district: { marginTop: 25, marginBottom: 0 },
      user: { margin: 0 },
      date: { margin: 0 },
      textBlock: { marginLeft: 25 },
      sectionHeader: { color: 'green', marginBottom: 0, marginTop: 20, bold: true },
      subHeader: { color: '#63656A', marginTop: 20, bold: true },
      footer: { fontSize: 10, color: 'gray' },
      introText: { lineHeight: 1 },
      'pdf-pagebreak-before': { marginTop: 50, color: 'green' },
    },
    header: function (currentPage, pageCount) {
      if (currentPage === 1) {
        return [
          {
            alignment: 'right',
            nodeName: 'IMG',
            image: 'img_ref_1',
            height: 159,
            width: 600,
            style: ['html-img'],
          },
        ]
      } else {
        return [
          {
            alignment: 'right',
            nodeName: 'IMG',
            image: 'img_ref_0',
            height: 53,
            marginRight: 10,
            marginTop: 10,
            paddingBottom: 20,
            marginBottom: 60,
            width: 60,
            style: ['html-img'],
          },
        ]
      }
    },
    images: { img_ref_0: headerImage, img_ref_1: headerBlockImage, img_ref_2: footerImage },
    footer: (currentPage, pageCount) => {
      if (currentPage !== 1) {
        return [
          {
            text: `SCALE Report - ${completedDate(assessmentReport)} | ${currentPage.toString()}`,
            alignment: 'right',
            marginRight: 10,
          },
          {
            alignment: 'center',
            nodeName: 'IMG',
            image: 'img_ref_2',
            height: 15,
            width: 600,
            marginBottom: 0,
            style: ['html-img'],
          },
        ]
      }
    },
    pageMargins: [20, 80, 20, 40],
    pageBreakBefore: function (currentNode) {
      return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1
    },
  }

  return pdfMake.createPdf(pdfConfig)
}

export const printReportPDF = (reportPDF, reportName) => {
  reportPDF.download(reportName)
}
