<template>
  <div class="p-grid">
    <div class="p-col-4 p-offset-1">
      <!-- all questions will have response/notes section -->
      <label for="name" class="extra-field-label">External Notes</label>
      <Textarea
        :modelValue="externalNotes"
        @input="(e) => $emit('update:externalNotes', e.target.value)"
        :autoResize="true"
        class="full-width-textarea"
        rows="5"
        cols="30"
      />
    </div>

    <div class="p-col-4 p-offset-1">
      <!-- all questions will have response/notes section -->
      <label for="name" class="extra-field-label">Confidential Notes</label>
      <Textarea
        :modelValue="confidentialNotes"
        @input="(e) => $emit('update:confidentialNotes', e.target.value)"
        :autoResize="true"
        class="full-width-textarea"
        rows="5"
        cols="30"
      />
    </div>
  </div>

  <template v-for="(keyMetric, kmi) in keyMetrics" :key="`key-metric-${kmi}`">
    <Divider class="p-my-6" />

    <div class="p-grid">
      <div class="p-col-4 p-offset-1">
        <label for="name" class="extra-field-label">Key Metric</label>
        <Textarea
          :modelValue="keyMetric.text"
          @input="(e) => updateKeyMetric(kmi, e.target.value)"
          :autoResize="true"
          class="full-width-textarea"
          rows="5"
          cols="30"
        />
        <Button
          @click="() => deleteKeyMetric(kmi)"
          label="Delete Key Metric"
          icon="pi pi-minus-circle"
          class="p-button-text delete-button"
        />
      </div>

      <div class="p-col-4 p-offset-1">
        <div
          v-for="(actionItem, aii) in keyMetric.actionItems"
          :key="aii"
          class="p-d-flex p-jc-between p-ai-center p-mb-4"
        >
          <label for="name" class="extra-field-label">Action Item</label>
          <InputText
            :modelValue="actionItem.text"
            @input="(e) => updateActionItem(kmi, aii, e.target.value)"
            v-model="answer"
            type="text"
            class="full-width-input"
          />
          <Button
            @click="() => deleteActionItem(kmi, aii)"
            icon="pi pi-minus-circle"
            class="p-button-text delete-button"
          />
        </div>
        <Button
          @click="() => addActionItem(kmi)"
          label="Add Action Item"
          icon="pi pi-plus-circle"
          class="p-button-text"
        />
      </div>
    </div>
  </template>

  <Divider class="p-my-6" />

  <div class="p-grid">
    <div class="p-col-4 p-offset-1">
      <Button
        @click="addKeyMetric"
        label="Add Key Metric"
        icon="pi pi-plus-circle"
        class="p-button-text"
      />
    </div>
  </div>

  <template v-for="(bestPractice, bpi) in bestPractices" :key="`best-practice-${bpi}`">
    <Divider class="p-my-6" />

    <div class="p-grid">
      <div class="p-col-4 p-offset-1">
        <label for="name" class="extra-field-label">Best Practice</label>
        <Textarea
          :modelValue="bestPractice.text"
          @input="(e) => updateBestPractice(bpi, e.target.value)"
          :autoResize="true"
          class="full-width-textarea"
          rows="5"
          cols="30"
        />
        <Button
          @click="() => deleteBestPractice(bpi)"
          label="Delete Best Practice"
          icon="pi pi-minus-circle"
          class="p-button-text delete-button"
        />
      </div>
    </div>
  </template>

  <Divider class="p-my-6" />

  <div class="p-grid">
    <div class="p-col-4 p-offset-1">
      <Button
        label="Add Best Practice"
        icon="pi pi-plus-circle"
        class="p-button-text"
        @click="addBestPractice"
      />
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import Divider from 'primevue/divider'

export default {
  components: {
    InputText,
    Textarea,
    Button,
    Divider,
  },
  props: {
    addKeyMetric: {
      type: Function,
    },
    addActionItem: {
      type: Function,
    },
    addBestPractice: {
      type: Function,
    },
    updateKeyMetric: {
      type: Function,
    },
    updateActionItem: {
      type: Function,
    },
    updateBestPractice: {
      type: Function,
    },
    deleteKeyMetric: {
      type: Function,
    },
    deleteActionItem: {
      type: Function,
    },
    deleteBestPractice: {
      type: Function,
    },
    assessmentDetails: {
      type: Object,
    },
    keyMetrics: {
      type: Array,
    },
    externalNotes: {
      type: String,
    },
    confidentialNotes: {
      type: String,
    },
    bestPractices: {
      type: Array,
    },
  },
  computed: {
    isDefault: {
      // Primevue wouldn't behave properly for checkboxes using the same
      // update methods as the other fields so it's being update with
      // this computed property. It functions the same way.
      get() {
        return this.currentAssessment.setupData.isDefault
      },
      set(val) {
        this.updateSetupData({ isDefault: val })
      },
    },
  },
}
</script>
