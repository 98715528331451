<template>
  <div class="route-wrapper">
    <Menubar :model="items" />
    <router-view />
  </div>

  <AppSidebar />
</template>

<script>
import Menubar from 'primevue/menubar'
import AppSidebar from '@/components/AppSidebar.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Menubar,
    AppSidebar,
  },
  data() {
    return {
      items: [
        {
          label: 'Evaluator',
          items: [
            {
              label: 'Settings',
              icon: 'pi pi-fw pi-cog',
              command: () => {
                this.$router.push('/settings')
              },
            },
            {
              label: 'Sign Out',
              icon: 'pi pi-fw pi-sign-out',
              command: () => {
                this.logout()
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('users', ['logout']),
  },
  mounted() {
    this.items[0].label = `${this.currentUser.full_name} (${this.currentUser.role})`
  },
}
</script>

<style lang="sass" scoped>
.route-wrapper
  margin-left: 243px
</style>
