<template>
  <div class="p-grid registration-page p-m-5">
    <div class="p-col-11 header p-d-flex p-ai-center p-xl-offset-1">
      <img
        src="https://storage.googleapis.com/chefann-prod-v1/images/SCALE-Logo.png"
        width="350"
        height="300"
        class="login-logo"
      />
    </div>
    <div class="p-col-12">
      <div class="p-grid p-formgrid">
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <div class="p-col-12 header p-d-flex p-ai-center">
            <img src="../../assets/icons/apple.png" class="p-mr-3" />
            <h1>Registration</h1>
          </div>
          <label for="email">E-MAIL*</label>
          <InputText
            data-testid="email"
            v-model="formData.email"
            :class="['full-width-input', v$.formData.email?.$error ? 'p-invalid' : '']"
            id="email"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="password">PASSWORD*</label>
          <InputText
            data-testid="password"
            v-model="formData.password"
            :class="['full-width-input', v$.formData.password?.$error ? 'p-invalid' : '']"
            id="password"
            type="password"
          />
        </div>
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="repeatPassword">CONFIRM PASSWORD*</label>
          <InputText
            data-testid="confirmPassword"
            v-model="formData.repeatPassword"
            :class="['full-width-input', v$.formData.repeatPassword?.$error ? 'p-invalid' : '']"
            id="repeatPassword"
            type="password"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="firstName">FIRST NAME*</label>
          <InputText
            data-testid="firstName"
            v-model="formData.firstName"
            :class="['full-width-input', v$.formData.firstName?.$error ? 'p-invalid' : '']"
            id="firstName"
            type="text"
          />
        </div>
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="lastName">LAST NAME*</label>
          <InputText
            data-testid="lastName"
            v-model="formData.lastName"
            :class="['full-width-input', v$.formData.lastName?.$error ? 'p-invalid' : '']"
            id="lastName"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="street">STREET ADDRESS*</label>
          <InputText
            data-testid="address"
            v-model="formData.profile.street"
            :class="['full-width-input', v$.formData.profile?.street?.$error ? 'p-invalid' : '']"
            id="street"
            type="text"
          />
        </div>
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="city">CITY*</label>
          <InputText
            data-testid="city"
            v-model="formData.profile.city"
            :class="['full-width-input', v$.formData.profile?.city?.$error ? 'p-invalid' : '']"
            id="city"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="state">STATE*</label>
          <Dropdown
            data-testid="state"
            v-model="formData.profile.state"
            :class="['full-width-input', v$.formData.profile?.state?.$error ? 'p-invalid' : '']"
            :options="states"
            id="state"
            optionLabel="name"
          />
        </div>
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="zip">ZIP (5 DIGITS)*</label>
          <InputText
            data-testid="zip"
            v-model="formData.profile.zip"
            :class="['full-width-input', v$.formData.profile?.zip?.$error ? 'p-invalid' : '']"
            id="zip"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="title">TITLE*</label>
          <Dropdown
            data-testid="title"
            v-model="formData.title"
            :class="['full-width-input', v$.formData.title?.$error ? 'p-invalid' : '']"
            :options="titles"
            id="title"
            optionLabel="name"
          />
        </div>
        <div class="p-col-11 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="district">SCHOOL DISTRICT*</label>
          <AutoComplete
            data-testid="district"
            v-model="formData.district"
            :suggestions="filteredDistrict"
            @complete="search($event)"
            field="name"
            placeholder="Search for School district"
            class="full-width-search"
          />
          <div v-if="formData.district.name" class="chipSelection">
            {{ formData.district.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-7 p-mt-5 p-xl-offset-1">
      <Button data-testid="submit" label="Save" class="blue-orange-button" @click="submit" />
    </div>
    <div>
      <p>
        SCALE is free and open to all school districts. If your district is not included in this
        list, we would be happy to add it to our database. Please email
        <a href="mailto:info@chefannfoundation.org"> info@chefannfoundation.org</a> with the
        district name and address.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, numeric } from '@vuelidate/validators'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import AutoComplete from 'primevue/autocomplete'
import 'primeicons/primeicons.css'

// Constants
import { states } from '@/data/constants'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    InputText,
    Dropdown,
    Button,
    AutoComplete,
  },
  data() {
    return {
      filteredDistrict: null,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        district: '',
        profile: {
          street: '',
          city: '',
          state: '',
          zip: '',
        },
        repeatPassword: '',
        password: '',
      },
      states,
      filteredDistricts: [],
    }
  },
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        title: { required },
        district: { required },
        profile: {
          street: { required },
          city: { required },
          state: { required },
          zip: { required, numeric, minLength: minLength(5), maxLength: maxLength(5) },
        },
        password: { required, minLength: minLength(8) },
        repeatPassword: { required, minLength: minLength(8) },
      },
    }
  },
  computed: {
    ...mapState(['titles']),
    classObject() {
      return {
        'p-hidden': !this.show,
      }
    },
  },
  methods: {
    search({ query }) {
      if (!query.trim()) {
        this.filteredDistrict = null
        return
      }
      // filter starts with 'district' and sort by name
      this.filteredDistrict = this.filteredDistricts
        .filter((district) => district.name.toLowerCase().startsWith(query.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    ...mapActions(['getDistricts', 'getTitles']),
    ...mapActions('users', ['createNewUser', 'uploadContact']),
    async filterByDistrict(e) {
      this.filteredDistricts = await this.getDistricts({ query: e.value })
    },
    submit() {
      this.v$.$touch()
      if (this.formData.password !== this.formData.repeatPassword) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Submission Error',
          detail: 'Passwords do not match.',
          life: 5000,
        })
        return
      } else if (this.formData.password.length < 8) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Submission Error',
          detail: 'Password must be at least 8 characters.',
          life: 5000,
        })
        return
      }
      if (this.v$.$error) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Submission Error',
          detail: 'Please fill out all form fields.',
          life: 5000,
        })
        return
      }

      const payload = {
        ...this.formData,
        title: this.formData.title?.id,
        district: this.formData.district?.id,
      }
      this.createNewUser({ payload, $toast: this.$toast })

      const createContactPayload = {
        FirstName: this.formData.firstName,
        LastName: this.formData.lastName,
        Email: this.formData.email,
        MailingStreet: this.formData.profile.street,
        MailingCity: this.formData.profile.city,
        MailingState: this.formData.profile.state.name,
        MailingPostalCode: this.formData.profile.zip,
        npsp__Primary_Affiliation__c: this.formData.district.account_id,
        Title: this.formData.title.name,
      }
      this.uploadContact({ createContactPayload, $toast: this.$toast })
    },
  },
  async mounted() {
    this.getTitles()
    this.filteredDistricts = await this.getDistricts({})
  },
}
</script>
<style>
.chipSelection {
  font-size: 1rem;
  color: #6b6b6b;
  background: #e3e3e3;
  padding: 0.3rem;
  border-radius: 0.3rem;
  max-width: fit-content;
  margin-top: 1rem;
}
.p-autocomplete-input {
  background-image: url('../../../node_modules/primeicons/raw-svg/search.svg');
  background-size: 1.5em;
  background-repeat: no-repeat;
  background-position: left 10px top 5px;
  text-indent: 35px;
}
</style>
