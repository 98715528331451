<template>
  <div class="p-grid take-assessment-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../../assets/icons/notes.png" class="p-mr-3" />
      <h1>Take Assessment</h1>
    </div>
    <div class="p-col-12 header p-d-flex p-ai-center">
      <h2 class="p-ml-2">{{ assessmentDetails?.assessment?.name }}</h2>
    </div>

    <div class="p-col-3">
      <Tree
        @node-select="onSidebarNodeSelect"
        :value="nestedQuestionsList"
        selectionMode="single"
        v-model:selectionKeys="selectedQuestion"
        :metaKeySelection="false"
      />
    </div>

    <div class="p-col-9 p-mb-6">
      <AssessmentQuestion
        :currentQuestionDetails="currentQuestionDetails"
        :isFieldInvalid="isFieldInvalid"
        :showResponse="displayResponses && showResponse"
        :notSelectedResponses="notSelectedResponses"
        v-model:answer="answer"
      />
    </div>
    <div
      v-if="
        assessmentDetails?.assessment?.get_type_display === 'On Site' ||
        (isHybridAssessment && isOnsiteRestricted && currentUser?.role === 'Superuser')
      "
      class="p-col-12 p-mb-6"
    >
      <ExtraFields
        :addKeyMetric="addKeyMetric"
        :addActionItem="addActionItem"
        :addBestPractice="addBestPractice"
        :assessmentDetails="assessmentDetails"
        :keyMetrics="keyMetrics"
        :bestPractices="extraFields.bestPractices"
        :updateBestPractice="updateBestPractice"
        :updateKeyMetric="updateKeyMetric"
        :updateActionItem="updateActionItem"
        :deleteBestPractice="deleteBestPractice"
        :deleteKeyMetric="deleteKeyMetric"
        :deleteActionItem="deleteActionItem"
        v-model:externalNotes="extraFields.externalNotes"
        v-model:confidentialNotes="extraFields.confidentialNotes"
      />
    </div>

    <div class="take-assessment-buttons p-col-12 p-d-flex p-jc-end p-ai-center">
      <Button
        v-if="isSelfAssessment && displayResponses && !showResponse"
        label="Save"
        class="blue-orange-button p-mr-3 p-ml-6"
        @click="saveQuestion"
      />
      <Button
        v-else-if="isSelfAssessment && displayResponses && showResponse"
        label="Next"
        class="blue-orange-button p-mr-3 p-ml-6"
        @click="nextQuestion"
      />
      <Button
        v-else
        label="Save & Next"
        class="blue-orange-button p-mr-3 p-ml-6"
        @click="saveQuestion"
      />
      <Button
        v-if="
          currentQuestionDetails?.completed ||
          (currentQuestionDetails?.self_assessment_completed && currentUser?.role !== 'Superuser')
        "
        label="Submit"
        class="blue-orange-button p-mr-3 p-ml-6"
        @click="submit"
      />
    </div>
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../../assets/icons/book.png" class="p-mr-3" />
      <p style="max-width: 650px">
        "You can start and stop at any time, progress up to this point is saved automatically. All
        generated responses will also be gathered and re-shared in the final report."
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import useVuelidate from '@vuelidate/core'
import Tree from 'primevue/tree'
import Button from 'primevue/button'
import AssessmentQuestion from '@/components/cards/AssessmentQuestion.vue'
import ExtraFields from '@/components/take-assessment/ExtraFields.vue'
import { event } from 'vue-gtag'
import _ from 'lodash'

// PDF Gen
import { reportName, reportPDF } from '../../utils/pdfHelper.js'

const actionItem = {
  text: '',
}

const keyMetric = {
  text: '',
  actionItems: [],
}

const bestPractice = {
  text: '',
}

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    Tree,
    Button,
    AssessmentQuestion,
    ExtraFields,
  },
  data() {
    return {
      showResponse: false,
      displayResponses: true,
      qaPayload: {},
      answer: { value: null },
      errors: [],
      keyMetrics: [],
      extraFields: {
        externalNotes: '',
        confidentialNotes: '',
        bestPractices: [],
      },
      selectedQuestion: {},
      unsubscribeQuestionUpdate: null,
    }
  },
  validations() {
    return {}
  },
  computed: {
    ...mapState('assessments', [
      'assessmentDetails',
      'questionTypes',
      'currentQuestionDetails',
      'nestedQuestionsList',
    ]),
    ...mapState('reports', ['assessmentReport']),
    ...mapState('users', ['currentUser']),
    isSelfAssessment() {
      return this.assessmentDetails.assessment?.get_type_display === 'Self Assessment'
    },
    isHybridAssessment() {
      return this.assessmentDetails.assessment?.get_type_display === 'Hybrid'
    },
    isOnsiteRestricted() {
      // `sec` for the question is the section's order in the assessment, not the section's ID
      return this.nestedQuestionsList[this.currentQuestionDetails.sec - 1]?.onsite_restricted
    },
    hasResponse() {
      try {
        const answer = JSON.parse(this.answer.value)
        const selectedResponse = answer.some((a) => a.selected_response)

        return selectedResponse
      } catch (e) {
        if (this.currentQuestionDetails.question.get_type_display === 'File') {
          return this.currentQuestionDetails.answers.some((a) => a.selected_response)
        } else if (this.answer?.selected_response) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    // TODO: add next functionality to navigate through questions
    // TODO: try pull question every time someone hits next
    ...mapActions('assessments', [
      'getQuestionTypes',
      'getAssessmentQuestion',
      'getQuestionById',
      'getNestedQuestionsList',
      'saveQuestionAnswer',
      'saveCompletedAssessment',
      'takeDistrictAssessment',
    ]),
    ...mapActions('reports', [
      'createDistrictAssessmentReport',
      'uploadDistrictAssessmentReportFiles',
    ]),
    ...mapMutations('assessments', ['updateAssessmentQuestion']),
    track_assessment_time() {
      event('assessment_session_end', {
        assessment: `${this.assessmentDetails?.assessment?.name}`,
        assessment_id: `${this.assessmentDetails?.assessment?.id}`,
        user: this.currentUser?.id,
        session_length: this.endTime - this.startTime,
      })
    },
    track_assessment_session() {
      event('assessment_session_start', {
        assessment: `${this.assessmentDetails?.assessment?.name}`,
        assessment_id: `${this.assessmentDetails?.assessment?.id}`,
        user: this.currentUser?.id,
      })
    },
    isFieldInvalid(id) {
      return this.errors.includes(id)
    },
    getQuestionFromId(id) {
      return this.questionTypes.find((type) => type.id === id)
    },
    addKeyMetric() {
      if (this.keyMetrics.length < 10) {
        this.keyMetrics.push(JSON.parse(JSON.stringify(keyMetric)))
      } else {
        this.$toast.add({
          severity: 'warning',
          group: 'tr',
          summary: 'Reached Max Key Metrics',
          detail: 'Can only add up to 10 key metrics.',
          life: 5000,
        })
      }
    },
    addActionItem(keyMetricIndex) {
      if (this.keyMetrics[keyMetricIndex].actionItems.length < 10) {
        this.keyMetrics[keyMetricIndex].actionItems.push({ ...actionItem })
      } else {
        this.$toast.add({
          severity: 'warning',
          group: 'tr',
          summary: 'Reached Max Action Items',
          detail: 'Can only add up to 10 action items per key metric.',
          life: 5000,
        })
      }
    },
    addBestPractice() {
      if (this.extraFields.bestPractices.length < 10) {
        this.extraFields.bestPractices.push({ ...bestPractice })
      } else {
        this.$toast.add({
          severity: 'warning',
          group: 'tr',
          summary: 'Reached Max Best Practices',
          detail: 'Can only add up to 10 best practices.',
          life: 5000,
        })
      }
    },
    deleteKeyMetric(keyMetricIndex) {
      this.keyMetrics.splice(keyMetricIndex, 1)
    },
    deleteActionItem(keyMetricIndex, actionItemIndex) {
      this.keyMetrics[keyMetricIndex].actionItems.splice(actionItemIndex, 1)
    },
    deleteBestPractice(index) {
      this.extraFields.bestPractices.splice(index, 1)
    },
    updateKeyMetric(index, value) {
      this.keyMetrics[index].text = value
    },
    updateActionItem(keyMetricIndex, actionItemIndex, value) {
      this.keyMetrics[keyMetricIndex].actionItems[actionItemIndex].text = value
    },
    updateBestPractice(index, value) {
      this.extraFields.bestPractices[index].text = value
    },
    // TODO: these save methods need to be refactored, but working for now.
    // there will be more logic here for validation
    saveQuestion() {
      this.validateAndSave()
    },
    nextQuestion() {
      const queryParams = this.currentQuestionDetails.queryParams

      const payload = {
        sec: queryParams.sec,
        sub: queryParams.sub,
        q: queryParams.q + 1,
      }
      // TODO: create means of retreiving next required unanswered question
      this.getQuestion(payload)

      const highlightedEl = document.getElementsByClassName('p-highlight')[0]
      if (highlightedEl) {
        highlightedEl.classList.remove('p-highlight')
      }
      const questionToSelect = document.getElementsByClassName(
        `question-id-${this.qaPayload.questionId}`,
      )[0]
      if (questionToSelect) {
        questionToSelect.classList.add('p-hightlight')
      }

      this.showResponse = false
      this.resetFormFields()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    validateAndSave() {
      if (
        !this.answer ||
        Object.entries(this.answer).length === 0 ||
        (this.currentQuestionDetails.question.get_type_display === 'Rollup' &&
          this.currentQuestionDetails.question.required === true &&
          !JSON.parse(this.answer.value).every((v) => {
            return !!v.value || v.value === 0
          }))
      ) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Invalid Response',
          detail: 'Please answer the question before saving.',
          life: 5000,
        })
        return false
      } else if (
        this.currentQuestionDetails.question.get_type_display === 'Rollup' &&
        this.currentQuestionDetails.question.required === true &&
        this.rollupQuestionTotal() !== this.rollupAnswersTotal()
      ) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Invalid Response Total',
          detail: 'Please ensure all answers sum to the question total before saving.',
          life: 5000,
        })
        return false
      }

      // if on-site assessment type:
      // save question answer
      let qaPayload = {
        // assessmentId: this.$route.params.id,
        districtAssessmentId: this.assessmentDetails.district_assessment.id,
        questionId: this.currentQuestionDetails?.question?.id,
        answer: this.answer,
      }

      if (
        this.assessmentDetails.assessment.get_type_display === 'On Site' ||
        (this.isHybridAssessment && this.isOnsiteRestricted)
      ) {
        qaPayload = {
          ...qaPayload,
          keyMetrics: this.keyMetrics,
          ...this.extraFields,
        }
      }
      this.qaPayload = qaPayload

      this.saveQuestionAnswer({ ...qaPayload, $toast: this.$toast })
        .then(() => {
          // Pull out all answers filled in so we can check if there are any `not_selected_response`s
          // that need to be shown.
          let parsedAnswers = [JSON.parse(JSON.stringify(this.answer))]
          if (
            ['Multiple Select', 'Rollup'].includes(
              this.currentQuestionDetails?.question?.get_type_display,
            )
          ) {
            parsedAnswers = JSON.parse(parsedAnswers[0].value)
          }

          // Check if the current number of answers equals the total number of answers,
          // then all answers have been selected and no `not_selected_response` should be shown.
          if (this.currentQuestionDetails.answers.length === parsedAnswers.length) {
            this.notSelectedResponses = []
          } else {
            // Otherwise, show the `not_selected_response` for each answer that has not been selected.
            this.notSelectedResponses = _.filter(
              _.differenceBy(this.currentQuestionDetails.answers, parsedAnswers, 'id').map(
                (a) => a.not_selected_response,
              ),
              (r) => r !== null && r !== '',
            )
          }

          if (
            !this.isSelfAssessment ||
            !this.displayResponses ||
            (!this.hasResponse && this.notSelectedResponses.length === 0)
          ) {
            this.nextQuestion()
          } else {
            this.showResponse = true
          }
        })
        .catch((err) => {
          console.log('Question answer failed to save', err)
        })
    },
    resetFormFields() {
      this.answer = { value: '' }
      this.errors = []
      this.extraFields = {
        externalNotes: '',
        confidentialNotes: '',
        bestPractices: [],
      }
      this.keyMetrics = []
    },
    getQuestion(queryParams) {
      const payload = {
        assessmentId: this.assessmentDetails.assessment.id,
        districtAssessmentId: this.assessmentDetails.district_assessment.id,
        queryParams: queryParams || this.currentQuestionDetails?.queryParams || {},
        $toast: this.$toast,
        sendTo: this.answer?.send_to || '',
      }
      this.getAssessmentQuestion(payload)
    },
    onSidebarNodeSelect(node) {
      if (node.data === 'question') {
        const districtAssessmentId = this.assessmentDetails.district_assessment.id
        this.getQuestionById({ questionId: node.key, districtAssessmentId, $toast: this.$toast })
        this.resetFormFields()
        this.showResponse = false
      }
    },
    async createAssessmentReport() {
      const districtAssessmentId = this.assessmentDetails.district_assessment.id
      const assessmentReport = await this.createDistrictAssessmentReport({
        districtAssessmentId,
        $toast: this.$toast,
      })

      if (assessmentReport) {
        this.$toast.add({
          severity: 'success',
          group: 'tr',
          summary: 'Assessment Completed!',
          detail: 'Scores will be available soon.',
          life: 5000,
        })
      }

      return assessmentReport
    },
    async submit() {
      if (
        this.assessmentDetails.assessment.get_type_display === 'Hybrid' &&
        this.currentUser?.role !== 'Superuser'
      ) {
        // For hybrid assessments being submitted by the self-assessor (non-admin) user, backend will update
        // `self_assessment_completed` as appropriate, so we only need to let them know their assessment is
        // "complete" (their part is, anyways) and redirect as normal
        this.$toast.add({
          severity: 'success',
          group: 'tr',
          summary: 'Assessment Completed!',
          life: 5000,
        })
      } else {
        // In most cases, submit district assessment, create assessment report, and upload PDF reports
        const districtAssessmentId = this.assessmentDetails.district_assessment.id
        const assessmentReport = await this.createAssessmentReport()

        if (assessmentReport) {
          reportPDF(assessmentReport, 'Detailed').getBase64((detailedReport) => {
            reportPDF(assessmentReport, 'Summary').getBase64((summaryReport) => {
              reportPDF(assessmentReport, 'Admin Detailed').getBase64((adminDetailedReport) => {
                this.uploadDistrictAssessmentReportFiles({
                  districtAssessmentId: districtAssessmentId,
                  reports: {
                    detailed: {
                      report: detailedReport,
                      name: reportName(assessmentReport, 'Detailed', this.currentUser) + '.pdf',
                    },
                    summary: {
                      report: summaryReport,
                      name: reportName(assessmentReport, 'Summary', this.currentUser) + '.pdf',
                    },
                    adminDetailed: {
                      report: adminDetailedReport,
                      name:
                        reportName(assessmentReport, 'Admin Detailed', this.currentUser) + '.pdf',
                    },
                  },
                })
              })
            })
          })
        }
      }

      if (this.currentUser?.role === 'School User') {
        this.$router.push('/')
      } else {
        this.$router.push('/assessments')
      }
    },
    rollupQuestionTotal() {
      const rollupConfig = this.currentQuestionDetails.question.rollup_config

      if (rollupConfig?.fixed_total) {
        return rollupConfig.fixed_total
      } else if (rollupConfig?.total_choice) {
        const answers = this.answer.value ? JSON.parse(this.answer.value) : []
        const totalChoice = answers?.find((a) => a.id === rollupConfig?.total_choice)
        return parseInt(totalChoice?.value)
      } else {
        return this.rollupAnswersTotal()
      }
    },
    rollupAnswersTotal() {
      // NOTE: logic duplicated in AssessmentQuestion's calculateRollupTotal
      if (!this.answer.value) {
        return 0
      }

      const rollupConfig = this.currentQuestionDetails.question.rollup_config
      const answers = JSON.parse(this.answer.value)

      const total = answers.reduce((sum, a) => {
        if (rollupConfig?.total_choice === a.id) {
          // Do not include total choice value in total of sub-choices
          return parseInt(sum || 0)
        } else {
          return parseInt(sum || 0) + parseInt(a.value || 0)
        }
      }, 0)

      return total
    },
  },
  mounted() {
    this.startTime = new Date()

    this.unsubscribeQuestionUpdate = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'assessments/updateAssessmentQuestion') {
        this.answer = mutation.payload.question_answer || {}

        // Only get the question choice if there isn't a `value` set for this answer
        if (this.answer.question_choice && !this.answer.value) {
          this.answer.id = this.answer.question_choice

          // Find matching answer from the quesiton to get the responses
          const match = this.currentQuestionDetails.answers.find((a) => a.id === this.answer.id)
          this.answer = match
        }

        if (this.answer?.length > 1) {
          const answers = []

          this.answer.forEach((ans) => {
            const currAns = this.currentQuestionDetails.answers.find(
              (a) => a.id === ans.question_choice,
            )

            if (currAns) {
              if (
                this.currentQuestionDetails.question.get_type_display === 'Rollup' ||
                this.currentQuestionDetails.question.get_type_display === 'Comparison'
              ) {
                // Set initial values in answer value (choices) prop from question answers
                currAns.value = ans.value
              }

              answers.push(currAns)
            }
          })

          this.answer = { value: JSON.stringify(answers) }
        } else if (
          !this.answer?.length &&
          (this.currentQuestionDetails.question.get_type_display === 'Rollup' ||
            this.currentQuestionDetails.question.get_type_display === 'Comparison')
        ) {
          // Set initial values in answer value (choices) prop from question choices
          this.answer = { value: JSON.stringify(this.currentQuestionDetails.answers) }
        } else if (this.currentQuestionDetails.question.get_type_display === 'File') {
          // If there isn't an `id` on the answer, then the user hasn't responded to the question yet
          // We should set the `value` to null for the notes field, and append the other answer details
          if (!this.answer.id) {
            this.answer = { value: null, ...this.currentQuestionDetails.answers[0] }
          } else {
            // Otherwise, ensure we pass the `value` from the answer for the notes field
            this.answer = { ...this.answer, value: mutation.payload.question_answer.value }
          }
        }

        this.extraFields = {
          confidentialNotes: mutation.payload.question_answer?.confidential_notes || '',
          externalNotes: mutation.payload.question_answer?.external_notes || '',
          // TODO: need to add best practices with action items here key metrics
          bestPractices: [],
        }
      }
    })

    const districtAssessmentId = this.$route.params.id

    this.takeDistrictAssessment({ districtAssessmentId, $toast: this.$toast })
      .then((res) => {
        // Configure whether to display responses for the whole assessment
        this.displayResponses =
          this.currentUser?.role === 'Superuser' || !res.data.assessment.is_reportless_default

        this.getQuestionTypes({ $toast: this.$toast })
        this.getNestedQuestionsList({ assessmentId: res.data.assessment.id, $toast: this.$toast })

        // Get the first question and start the assessment time
        this.getQuestion()
        this.track_assessment_session()
      })
      .catch((err) => {
        console.log('Could not fetch assessment:', err)
      })
  },
  unmounted() {
    this.updateAssessmentQuestion({})
    this.endTime = new Date()
    this.track_assessment_time()
  },
}
</script>

<style lang="sass" scoped>
.blue-orange-button
  width: 231.71px
.p-tree
  width: 100%
.take-assessment-buttons
  position: fixed
  bottom: 20px
  right: 30px
</style>
