<template>
  <div class="p-grid registration-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../assets/icons/chart.png" class="p-mr-3" />
      <h1>User & District Information</h1>
    </div>
    <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-mb-lg-0 p-d-flex p-flex-column">
      <label for="first_name">FIRST NAME:</label>
      <InputText
        v-model="formData.first_name"
        id="firstName"
        type="text"
        class="full-width-input"
      />

      <label for="email">E-MAIL:</label>
      <InputText v-model="formData.email" id="email" type="text" class="full-width-input" />

      <label for="city">CITY:</label>
      <InputText
        v-model="formData.user_profile.city"
        id="city"
        type="text"
        class="full-width-input"
      />
      <div class="p-col-5" style="margin-left: -5px">
        <label for="zip">ZIP:</label>
        <InputText
          v-model="formData.user_profile.zip"
          id="zip"
          type="text"
          class="half-width-input"
        />
      </div>
    </div>

    <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
      <label for="last_name">LAST NAME:</label>
      <InputText
        v-model="formData.last_name"
        id="last_name"
        type="text"
        class="full-width-input"
      />
      <label for="street">STREET ADDRESS:</label>
      <InputText
        v-model="formData.user_profile.street"
        id="street"
        type="text"
        class="full-width-input"
      />

      <div class="p-grid" style="margin-top: 1px">
        <div class="p-col-5 p-py-0" style="padding-left: 0.5rem">
          <label for="state">STATE:</label>
          <Dropdown
            v-model="formData.user_profile.state"
            :options="states"
            id="state"
            optionLabel="name"
            optionValue="code"
            class="half-width-input"
          />
        </div>
      </div>
    </div>

    <div class="p-col-12 p-mt-5">
      <Button @click="submit" label="Update User" class="blue-orange-button" />
    </div>

    <div style="margin: 30px 10px 10px 10px">
      <div class="questions p-d-flex">
        <div class="question p-d-flex">
          <span>DISTRICT TYPE:</span>
          <div class="options">
            <template v-for="option in districtTypeOptions" :key="option.value">
              <div class="option">
                <RadioButton
                  :name="`district-type-${option.value}`"
                  :value="option.value"
                  :modelValue="this.districtType"
                  @click="() => updateDistrictType(option.value)"
                />
                <label :for="`label`" @click="() => updateDistrictType(option.value)">{{
                  option.label
                }}</label>
              </div>
            </template>
          </div>
        </div>

        <div class="question p-d-flex">
          <span>STUDENT ENROLLMENT:</span>
          <div class="options">
            <template v-for="option in districtEnrollmentBrackets" :key="option.value">
              <div class="option">
                <RadioButton
                  :name="`district-type-${option.value}`"
                  :value="option.value"
                  :modelValue="this.districtEnrollmentBracket"
                  @click="() => updateDistrictEnrollmentBracket(option.value)"
                />
                <label
                  :for="`label`"
                  @click="() => updateDistrictEnrollmentBracket(option.value)"
                  >{{ option.label }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="question p-d-flex" style="margin-top: 30px">
        <span>Would you like to opt-in to the Peer District Network?</span>
        <p style="font-weight: normal">
          <em>
            When you opt-in to the Peer District Network, Assessments that are configured to match
            districts may match your district to another based on the results of the assessment.
          </em>
        </p>
        <div class="option" style="margin-top: -10px; margin-bottom: 20px">
          <Checkbox
            :modelValue="this.peerNetworkOptIn"
            :binary="true"
            @change="() => updatePeerNetworkOptIn(!this.peerNetworkOptIn)"
            name="opt-in"
          />
          <label :for="'opt-in'" @click="() => updatePeerNetworkOptIn(!this.peerNetworkOptIn)"
            >Opt-In</label
          >
        </div>
      </div>
    </div>

    <div class="p-col-12 p-mt-2">
      <Button
        :disabled="checkDistrictInformation()"
        @click="submitDistrictInformation()"
        label="Update District"
        class="blue-orange-button"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'

// Constants
import { districtEnrollmentBrackets, districtTypeOptions, states } from '@/data/constants'

export default {
  components: {
    Button,
    Checkbox,
    Dropdown,
    InputText,
    RadioButton,
  },
  data() {
    return {
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        user_profile: {
          street: '',
          city: '',
          state: '',
          zip: '',
        },
        role: '',
      },
      states,
      districtTypeOptions,
      districtEnrollmentBrackets,
      districtType: null,
      districtEnrollmentBracket: null,
      peerNetworkOptIn: false,
    }
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('users', ['saveSettings', 'getCurrentUser', 'updateDistrictInformation']),
    submit() {
      // TODO: validate
      const payload = { ...this.formData }
      if (this.formData.user_profile?.state?.code) {
        payload.user_profile.state = this.formData.user_profile.state.code
      }
      this.saveSettings({ payload, $toast: this.$toast })
    },
    checkDistrictInformation() {
      return this.districtType === null || this.districtEnrollmentBracket === null
    },
    updateDistrictType(value) {
      this.districtType = value
    },
    updateDistrictEnrollmentBracket(value) {
      this.districtEnrollmentBracket = value
    },
    updatePeerNetworkOptIn(value) {
      this.peerNetworkOptIn = value
    },
    submitDistrictInformation() {
      this.updateDistrictInformation({
        id: this.currentUser.user_profile.district.id,
        type: this.districtType,
        student_enrollment_range: this.districtEnrollmentBracket,
        opt_in_peer_network: this.peerNetworkOptIn,
        $toast: this.$toast,
      })
      this.displayDistrictInfoDialog = false
    },
  },
  async mounted() {
    this.getCurrentUser({ $toast: this.$toast })

    // populate initial form data
    const user = this.currentUser

    const state = states.find((state) => {
      return state.code === user?.user_profile?.state
    })

    this.formData = {
      ...this.formData,
      first_name: user?.full_name?.split(' ')[0] || '',
      last_name: user?.full_name?.split(' ')[1] || '',
      email: user?.email || '',
      user_profile: {
        street: user?.user_profile?.street || '',
        city: user?.user_profile?.city || '',
        state: state || '',
        zip: user?.user_profile?.zip || '',
      },
    }

    this.districtType = user?.user_profile?.district.type
    this.districtEnrollmentBracket = user?.user_profile?.district.student_enrollment_range
    this.peerNetworkOptIn = user?.user_profile?.district.opt_in_peer_network
  },
}
</script>
<style lang="sass" scoped>
.blue-orange-button
  width: 160px

.questions
  display: flex
  gap: 50px

.question
  display: flex
  flex-direction: column

  span
    font-weight: bold

  p
    margin-top: -5px

  label
    margin: 0

.options
  display: flex
  flex-direction: column

.option
  display: flex
  flex-direction: row
  align-items: center
  gap: 5px
</style>
