<template>
  <div class="p-grid registration-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../../assets/icons/apple.png" class="p-mr-3" />
      <h1>Add User</h1>
    </div>
    <div class="p-col-12">
      <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="firstName">FIRST NAME*</label>
          <InputText
            data-testid="firstName"
            :disabled="loading"
            v-model="formData.firstName"
            :class="['full-width-input', v$.formData.firstName?.$error ? 'p-invalid' : '']"
            id="firstName"
            type="text"
          />
        </div>
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="lastName">LAST NAME*</label>
          <InputText
            data-testid="lastName"
            :disabled="loading"
            v-model="formData.lastName"
            :class="['full-width-input', v$.formData.lastName?.$error ? 'p-invalid' : '']"
            id="lastName"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="email">E-MAIL*</label>
          <InputText
            data-testid="email"
            :disabled="loading"
            v-model="formData.email"
            :class="['full-width-input', v$.formData.email?.$error ? 'p-invalid' : '']"
            id="email"
            type="text"
          />
        </div>
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="street">STREET ADDRESS*</label>
          <InputText
            data-testid="address"
            :disabled="loading"
            v-model="formData.profile.street"
            :class="['full-width-input', v$.formData.profile?.street?.$error ? 'p-invalid' : '']"
            id="street"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="city">CITY*</label>
          <InputText
            data-testid="city"
            :disabled="loading"
            v-model="formData.profile.city"
            :class="['full-width-input', v$.formData.profile?.city?.$error ? 'p-invalid' : '']"
            id="city"
            type="text"
          />
        </div>
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="state">STATE*</label>
          <Dropdown
            data-testid="state"
            :disabled="loading"
            v-model="formData.profile.state"
            :class="['full-width-input', v$.formData.profile?.state?.$error ? 'p-invalid' : '']"
            :options="states"
            id="state"
            optionLabel="name"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="title">TITLE*</label>
          <Dropdown
            data-testid="title"
            :disabled="loading"
            v-model="formData.title"
            :class="['full-width-input', v$.formData.title?.$error ? 'p-invalid' : '']"
            :options="titles"
            id="title"
            optionLabel="name"
          />
        </div>
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="zip">ZIP (5 DIGITS)*</label>
          <InputText
            data-testid="zip"
            :disabled="loading"
            v-model="formData.profile.zip"
            :class="['full-width-input', v$.formData.profile?.zip?.$error ? 'p-invalid' : '']"
            id="zip"
            type="text"
          />
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="role">ROLE*</label>
          <Dropdown
            data-testid="role"
            :disabled="loading"
            v-model="formData.role"
            :class="['full-width-input', v$.formData.role?.$error ? 'p-invalid' : '']"
            :options="roles"
            id="role"
            optionLabel="name"
          />
        </div>
        <div class="p-col-12 p-mb-2 p-lg-6 p-xl-4 p-xl-offset-1 p-mb-lg-0 p-d-flex p-flex-column">
          <label for="district">SCHOOL DISTRICT*</label>
          <Dropdown
            data-testid="district"
            :disabled="loading"
            v-model="formData.district"
            :class="['full-width-input', v$.formData.district?.$error ? 'p-invalid' : '']"
            :options="filteredDistricts"
            optionLabel="name"
            filterPlaceholder="Find School District"
            :showClear="true"
            :filter="true"
            @filter="filterByDistrict($event)"
            dataKey="id"
          />
        </div>
      </div>
    </div>

    <div class="p-col-7 p-mt-5">
      <Button
        data-testid="submit"
        :disabled="loading"
        :label="loading ? 'Adding User...' : 'Add User'"
        class="blue-orange-button"
        style="width: auto !important"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, numeric } from '@vuelidate/validators'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

import { states } from '@/data/constants'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    InputText,
    Dropdown,
    Button,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        district: '',
        profile: {
          street: '',
          city: '',
          state: '',
          zip: '',
        },
        role: '',
      },
      loading: false,
      states,
      filteredDistricts: [],
    }
  },
  validations() {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        title: { required },
        district: { required },
        profile: {
          street: { required },
          city: { required },
          state: { required },
          zip: { required, numeric, minLength: minLength(5), maxLength: maxLength(5) },
        },
        role: { required },
      },
    }
  },
  computed: {
    ...mapState(['titles', 'roles']),
  },
  methods: {
    ...mapActions(['getDistricts', 'getTitles', 'getRoles']),
    ...mapActions('users', ['register']),
    async filterByDistrict(e) {
      this.filteredDistricts = await this.getDistricts({ query: e.value })
    },
    submit() {
      this.v$.$touch()
      if (this.v$.$error) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Submission Error',
          detail: 'Please fill out all all form fields.',
          life: 5000,
        })
        return
      }

      const payload = {
        ...this.formData,
        role: this.formData.role?.id,
        title: this.formData.title?.id,
        district: this.formData.district?.id,
      }

      this.loading = true
      this.register({ payload, $toast: this.$toast })
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
  async mounted() {
    this.getTitles()
    this.getRoles()
    this.filteredDistricts = await this.getDistricts({})
  },
}
</script>
