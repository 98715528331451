<template>
  <div class="p-grid preview-assessment-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../../assets/icons/chart.png" class="p-mr-3" />
      <h1>Preview SubSection</h1>
    </div>
    <router-link :to="{ path: '/assessment-creator/' + assessmentDetails?.id + '/edit' }">
      Back to creator
    </router-link>
    <template v-for="question in previewQuestions" :key="`${question.id}`">
      <div class="p-col-12 p-mb-6">
        <PreviewQuestion
          :currentQuestionDetails="question"
          :isFieldInvalid="isFieldInvalid"
          :showResponse="showResponse"
          v-model:answer="answer"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PreviewQuestion from '@/components/cards/PreviewQuestion.vue'

export default {
  data() {
    return {
      test: 'value',
    }
  },
  components: {
    PreviewQuestion,
  },
  computed: {
    ...mapState('assessments', ['assessmentDetails', 'previewQuestions']),
  },
  methods: {
    ...mapActions('assessments', ['displaySubSectionPreview']),
  },
  mounted() {},
}
</script>
