<template>
  <AuthScreenLayout>
    <div class="p-col-5">
      <p>E-MAIL*</p>
    </div>
    <div class="p-col-7">
      <InputText
        data-testid="email"
        @keydown.enter="submit"
        v-model="email"
        :class="['full-width-input', v$.email?.$error ? 'p-invalid' : '']"
        type="text"
      />
    </div>
    <div class="p-col-5">
      <p>PASSWORD*</p>
    </div>
    <div class="p-col-7">
      <Password
        data-testid="password"
        @keydown.enter="submit"
        v-model="password"
        :class="['full-width-input', v$.password?.$error ? 'p-invalid' : '']"
        :feedback="false"
        style="width: 100%"
        toggleMask
      />
    </div>
    <div class="p-col p-text-right">
      <a :href="VUE_APP_API_URL_RESET"> Forgot Password?</a>
    </div>
    <!-- <div class="p-col-5">
      <p><Checkbox v-model="remember" :binary="true" class="p-mr-2" /> Remember Me</p>
    </div> -->
    <div class="p-col-12 p-d-flex p-jc-between">
      <Button
        data-testid="register"
        @click="$router.push('/new-registration')"
        label="Register for Account"
        class="p-button-text underline-button"
      />
      <Button
        data-testid="submit"
        @click="submit"
        :disabled="!email || !password"
        label="Login"
        class="p-button-text underline-button"
      />
    </div>
  </AuthScreenLayout>
</template>

<script>
import { mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
// import Checkbox from 'primevue/checkbox'
import AuthScreenLayout from '@/layouts/AuthScreenLayout.vue'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    AuthScreenLayout,
    InputText,
    Password,
    Button,
    // Checkbox,
  },
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      VUE_APP_API_URL_RESET: process.env.VUE_APP_API_URL_RESET,
    }
  },
  validations() {
    return {
      email: { required, email },
      password: { required, minLength: minLength(8), maxLength: maxLength(128) },
    }
  },
  methods: {
    ...mapActions('users', ['login']),
    submit() {
      this.v$.$touch()
      if (this.v$.$error) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Login Error',
          detail: 'Please fill out all all form fields.',
          life: 5000,
        })
        return
      }

      const payload = {
        email: this.email,
        password: this.password,
      }
      this.login({ payload, $toast: this.$toast })
    },
  },
}
</script>
