<template>
  <div>
    <label for="name">ASSESSMENT NAME*</label><br />
    <InputText
      @input="updateName"
      :value="currentAssessment.setupData.name"
      :class="['full-width-input', setupDataFormErrors.includes('name') ? 'p-invalid' : '']"
      id="name"
      type="text"
    />
  </div>
  <div>
    <label for="description">REPORT DESCRIPTION</label><br />
    <QuillEditor
      :value="currentAssessment.setupData.description"
      :options="editorOptions"
      @change="onEditorChange($event)"
    />
  </div>
  <div>
    <label for="quickdescription">QUICK DESCRIPTION</label><br />
    <Textarea
      @input="updateQuickdescription"
      v-model="currentAssessment.setupData.quickdescription"
      :class="[
        'full-width-input',
        setupDataFormErrors.includes('quickdescription') ? 'p-invalid' : '',
      ]"
      id="quickdescription"
      type="textarea"
    />
  </div>
  <div>
    <label for="type">ASSESSMENT TYPE*</label><br />
    <Dropdown
      @change="updateType"
      :modelValue="currentAssessment.setupData.type"
      :options="types"
      :class="['full-width-input', setupDataFormErrors.includes('type') ? 'p-invalid' : '']"
      optionLabel="name"
    />
  </div>
  <div>
    <label for="district">SCHOOL DISTRICT(S)</label><br />
    <MultiSelect
      :modelValue="currentAssessment.setupData.districts"
      @change="updateSelectedDistricts"
      :options="filteredDistricts"
      optionLabel="name"
      class="full-width-input"
      :filter="true"
      filterPlaceholder="Search Districts"
      @filter="filterByDistrict($event)"
      dataKey="id"
    />
  </div>
  <div class="p-mt-5">
    <Checkbox
      v-model="isDefault"
      :binary="true"
      :disabled="currentAssessment.setupData.type?.name === 'On Site'"
      name="is-default"
      class="p-mr-2"
    />
    <label for="is-reportless" class="p-m-0">DEFAULT ASSESSMENT</label><br />
  </div>
  <div class="p-mt-5">
    <Checkbox
      v-model="hideSchoolUserReports"
      :binary="true"
      :disabled="currentAssessment.setupData.type?.name === 'On Site'"
      name="is-reportless"
      class="p-mr-2"
    />
    <label for="is-reportless" class="p-m-0">HIDE SCHOOL USER REPORTS</label><br />
  </div>
  <div class="p-mt-5">
    <Checkbox
      v-model="enablePeerDistrict"
      :binary="true"
      name="enable-peer-district"
      class="p-mr-2"
    />
    <label for="enable-peer-district" class="p-m-0">ENABLE PEER DISTRICT MATCHING</label><br />
  </div>
  <div v-if="currentAssessment.setupData.enablePeerDistrict">
    <label for="offer-threshold" class="p-mb-0">OFFER THRESHOLD</label>
    <small class="block p-mb-2"
      ><em
        >Section scores must meet or exceed this threshold for a district to be recommended as a
        peer district match.</em
      ></small
    >
    <InputText
      @input="updateOfferThreshold"
      v-model="currentAssessment.setupData.offerThreshold"
      :class="[
        'percent-input-full-width',
        setupDataFormErrors.includes('offer-threshold') ? 'p-invalid' : '',
      ]"
      id="offer-threshold"
      type="number"
    />
    <span class="percent-symbol">%</span>
  </div>
  <div v-if="currentAssessment.setupData.enablePeerDistrict">
    <label for="receive-threshold" class="p-mb-0">RECEIVE THRESHOLD</label>
    <small class="block p-mb-2"
      ><em
        >Section scores must be at or below this threshold for a district to receive a peer
        district recommendation.</em
      ></small
    >
    <InputText
      @input="updateReceiveThreshold"
      v-model="currentAssessment.setupData.receiveThreshold"
      :class="[
        'percent-input-full-width',
        setupDataFormErrors.includes('receive-threshold') ? 'p-invalid' : '',
      ]"
      id="receive-threshold"
      type="number"
    />
    <span class="percent-symbol">%</span>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { quillEditor as QuillEditor } from 'vue3-quill'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import Checkbox from 'primevue/checkbox'
import Textarea from 'primevue/textarea'

export default {
  components: {
    QuillEditor,
    InputText,
    Dropdown,
    MultiSelect,
    Checkbox,
    Textarea,
  },
  props: {
    types: {
      type: Array,
    },
    setupDataFormErrors: {
      type: Array,
    },
  },
  data() {
    return {
      editorOptions: {
        placeholder: 'Enter Text',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
          ],
        },
      },
      filteredDistricts: [],
    }
  },
  computed: {
    ...mapState('assessments', ['currentAssessment']),
    isDefault: {
      // Primevue wouldn't behave properly for checkboxes using the same
      // update methods as the other fields so it's being update with
      // this computed property. It functions the same way.
      get() {
        return this.currentAssessment.setupData.isDefault
      },
      set(val) {
        this.updateSetupData({ isDefault: val })
      },
    },
    hideSchoolUserReports: {
      get() {
        return this.currentAssessment.setupData.isReportless
      },
      set(val) {
        this.updateSetupData({ isReportless: val })
      },
    },
    enablePeerDistrict: {
      get() {
        return this.currentAssessment.setupData.enablePeerDistrict
      },
      set(val) {
        this.updateSetupData({ enablePeerDistrict: val })
      },
    },
  },
  methods: {
    ...mapMutations('assessments', ['updateSetupData']),
    ...mapActions(['getDistricts']),
    updateName(e) {
      this.updateSetupData({ name: e.target.value })
    },
    onEditorChange({ quill, html, text }) {
      this.updateSetupData({ description: html })
    },
    updateQuickdescription(e) {
      this.updateSetupData({ quickdescription: e.target.value })
    },
    updateOfferThreshold(e) {
      this.updateSetupData({ offerThreshold: e.target.value })
    },
    updateReceiveThreshold(e) {
      this.updateSetupData({ receiveThreshold: e.target.value })
    },
    updateType(e) {
      this.updateSetupData({ type: e.value })
    },
    updateSelectedDistricts(e) {
      this.updateSetupData({ districts: e.value })
    },
    async filterByDistrict(e) {
      this.filteredDistricts = await this.getDistricts({ query: e.value })
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      // this condition runs if they are editing an assessment instead of
      // creating a new one
      // TODO: prepopulate form data here from assessment
    }
    this.filteredDistricts = await this.getDistricts({})
  },
}
</script>
