<template>
  <Menu :model="menuItems" />
</template>

<script>
import Menu from 'primevue/menu'

export default {
  components: {
    Menu,
  },
  props: {
    menuItems: {
      type: Array,
    },
  },
}
</script>

<style lang="sass">
.p-menu.p-component
  width: 270px

  @media (min-width: 1550px)
    width: 300px
</style>
