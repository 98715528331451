<template>
  <Loading
    :active="loadingReport || loading"
    :can-cancel="false"
    :lock-scroll="true"
    :height="70"
    :width="70"
    loader="bars"
  />

  <div class="p-col-12">
    <WideAssessment
      :assessment="assessmentReport?.assessment"
      :report="assessmentReport?.report?.report_data"
      :isReport="true"
      :completedAt="assessmentReport?.report?.report_data?.created_at?.split('T')[0]"
      :percentageScore="totalPoints"
    />
  </div>

  <img id="results" />

  <div class="p-col-12">
    <SelectButton
      class="reportSelect"
      v-model="selectedDisplay"
      :options="displayOptions"
      aria-labelledby="Report selection"
    />
    <p v-if="selectedSummaryTab || selectedDetailedTab">
      Thank you for completing a SCALE Assessment! This report has been generated from your answers
      and inputs. Each question you answered is built into your score, and some sections will have
      feedback associated. Not every answer will generate feedback.
    </p>
    <p v-if="selectedPeerNetworkTab">
      Based on your performance on this assessment, you've been matched with another district in
      your peer network. After reviewing the section{{
        sectionReportMatches().length === 1 ? '' : 's'
      }}
      listed below, feel free to reach out to the contact for the district you were matched with to
      discuss and collaborate on improvements in those areas!
    </p>
  </div>

  <div v-if="selectedPeerNetworkTab">
    <Card class="section-card p-mx-2 p-mt-2 p-mb-4">
      <template #content>
        <div class="p-grid">
          <h2 class="p-ml-2">Peer District Match</h2>

          <p class="p-col-12 p-mt-0">
            You have been matched with the following peer district: <br />
            <strong>{{
              peerDistrictRecommendation?.recommendation?.recommended_district_name
            }}</strong>
          </p>
          <p class="p-col-12 p-mt-0">
            District Contact: <br />
            <strong>{{
              peerDistrictRecommendation?.recommendation?.recommended_district_contact?.full_name
            }}</strong>
            (<a
              href="mailto:{{ peerDistrictRecommendation?.recommendation?.recommended_district_contact?.email }}"
            >
              {{
                peerDistrictRecommendation?.recommendation?.recommended_district_contact?.email
              }} </a
            >)
          </p>
        </div>
      </template>
    </Card>
  </div>

  <Accordion v-if="showSections" id="assessment-report" :multiple="true" class="p-col-12">
    <AccordionTab v-for="se in sectionsAndSubsections()" :key="se" :header="sectionHeader(se)">
      <template v-if="se.subsections[0].subsection_data?.section_description">
        <Card class="section-card p-mb-4">
          <template #content>
            <h2>Section Description</h2>
            <div>
              <Divider class="p-mb-4" />
              <div class="p-grid">
                <p>{{ se.subsections[0].subsection_data?.section_description }}</p>
              </div>
            </div>
          </template>
        </Card>
      </template>
      <template v-if="prioritizeSection(se)">
        <Card class="section-card p-mb-4">
          <template #content>
            <div>
              <div class="p-grid">
                <p>{{ sectionScoreData(se).score_response }}</p>
              </div>
            </div>
          </template>
        </Card>
      </template>
      <Accordion id="assessment-report-sections" :multiple="true" class="p-col-12">
        <AccordionTab
          v-for="st in se.subsections"
          :key="st"
          :header="`${st.subsection_data?.subsection_name} : ${
            st.subsection_data?.actual_score || 0
          }`"
        >
          <template v-if="st.subsection_data?.subsection_description">
            <Card class="section-card p-mb-4">
              <template #content>
                <h2>Subsection Description</h2>
                <div>
                  <Divider class="p-mb-4" />
                  <div class="p-grid">
                    <p>{{ st.subsection_data?.subsection_description }}</p>
                  </div>
                </div>
              </template>
            </Card>
          </template>
          <template v-if="st.key_recommendations?.length">
            <Card class="section-card p-mb-4">
              <template #content>
                <h2>Key Recommendations:</h2>
                <div v-for="kr in st.key_recommendations" :key="kr?.id">
                  <Divider class="p-mb-4" />
                  <p>
                    <strong>{{ kr?.text }}</strong>
                  </p>
                  <ol>
                    <li v-for="ai in kr?.action_items" :key="ai?.id">
                      {{ ai?.text }}
                    </li>
                  </ol>
                </div>
              </template>
            </Card>
          </template>
          <template v-if="st.best_practices?.length">
            <Card class="section-card p-mb-4">
              <template #content>
                <h2>Best Practices: {{ bp?.name }}</h2>
                <div v-for="bp in st.best_practices" :key="bp?.id">
                  <Divider class="p-mb-4" />
                  <p>{{ bp?.text }}</p>
                </div>
              </template>
            </Card>
          </template>
          <template
            v-if="st.question_responses.length > 0 || st.not_selected_responses.length > 0"
          >
            <Card class="section-card p-mb-4">
              <template #content>
                <div v-if="selectedDetailedTab || selectedPeerNetworkTab">
                  <h2>Questions/Answers and Responses</h2>
                  <div
                    v-for="questionDetail in mergeQuestionDetails(st)"
                    :key="questionDetail?.id"
                  >
                    <Divider class="p-mb-4" />
                    <div
                      v-if="
                        !isRollupQuestion(questionDetail) &&
                        !isComparisonQuestion(questionDetail) &&
                        !isMultiSelectQuestion(questionDetail)
                      "
                    >
                      <p>Q: {{ questionDetail.text }}</p>
                      <p>
                        A:
                        {{ questionDetail.answers[0].choice || questionDetail.answers[0].value }}
                      </p>
                      <div v-if="questionDetail?.answers[0].selected_response">
                        Response:
                        <div v-html="questionDetail?.answers[0].selected_response"></div>

                        <div v-if="questionDetail?.not_selected_responses?.length > 0">
                          <div v-html="questionDetail?.not_selected_responses"></div>
                        </div>
                      </div>
                      <div
                        v-if="
                          questionDetail?.answers[0].external_notes ||
                          (questionDetail?.answers[0].confidential_notes &&
                            currentUser?.role === 'Superuser')
                        "
                        class="p-grid"
                      >
                        <div class="p-col-6">
                          <h4>External Notes</h4>
                          <p>{{ questionDetail?.answers[0].external_notes || 'No notes.' }}</p>
                        </div>
                        <div v-if="currentUser?.role === 'Superuser'" class="p-col-6">
                          <h4>Confidential Notes</h4>
                          <p>
                            {{ questionDetail?.answers[0].confidential_notes || 'No notes.' }}
                          </p>
                        </div>
                      </div>
                      <div
                        v-if="
                          questionDetail?.answers[0].key_recommendations &&
                          questionDetail?.answers[0].key_recommendations.length > 0
                        "
                      >
                        <h4>Key Recommendations</h4>
                        <div
                          v-for="kr in questionDetail?.answers[0].key_recommendations"
                          :key="kr.id"
                        >
                          <p>{{ kr.text }}</p>
                          <ol>
                            <li v-for="ai in kr.action_items" :key="ai.id">
                              {{ ai.text }}
                            </li>
                          </ol>
                        </div>
                      </div>
                      <div
                        v-if="
                          questionDetail?.answers[0].best_practices &&
                          questionDetail?.answers[0].best_practices.length > 0
                        "
                      >
                        <h4>Best Practices</h4>
                        <div v-for="bp in questionDetail?.answers[0].best_practices" :key="bp.id">
                          <p>{{ bp.text }}</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="isRollupQuestion(questionDetail)">
                      <p>Q: {{ questionDetail.text }}</p>
                      <p>
                        {{ questionDetail.choice }} - {{ questionDetail.value
                        }}{{ questionDetail.display_format === 'percent' ? '%' : '' }}
                      </p>
                      <div v-if="questionDetail?.selected_response">
                        Response:
                        <div v-html="questionDetail?.selected_response"></div>
                      </div>
                    </div>
                    <div v-if="isComparisonQuestion(questionDetail)">
                      <p>Q: {{ questionDetail.text }}</p>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          margin-bottom: 5px;
                        "
                      >
                        <div
                          v-for="(choice, i) in questionDetail.choices"
                          :key="choice.text"
                          style="display: flex; flex-direction: row; align-items: center"
                        >
                          <p class="p-m-0">{{ choice.text }}</p>
                          :
                          <p style="margin: 0; margin-left: 5px">
                            {{ choice.value }}
                          </p>
                          <p v-if="i === 0" style="margin: 0 5px 0 5px">
                            {{ questionDetail.operator }}
                          </p>
                        </div>
                      </div>
                      <div style="">
                        <p style="margin-top: 0">
                          Result =
                          {{
                            calculateComparison(
                              questionDetail.operator,
                              questionDetail.choices[0].value,
                              questionDetail.choices[1].value,
                            )
                          }}
                        </p>
                      </div>
                      <template
                        v-if="
                          questionDetail.choices.some(
                            (c) => c.selected_response !== null && c.selected_response !== '',
                          )
                        "
                      >
                        Response:
                        <div
                          v-for="choice in questionDetail.choices"
                          :key="choice.selected_response"
                        >
                          <div v-html="choice.selected_response"></div>
                        </div>
                      </template>
                    </div>
                    <div v-if="isMultiSelectQuestion(questionDetail)">
                      <p>Q: {{ questionDetail.text }}</p>
                      <p>
                        A: {{ questionDetail.answers.map((answer) => answer.choice).join(', ') }}
                      </p>
                      <div v-if="questionDetail?.selected_response">
                        Response:
                        <div
                          v-html="
                            questionDetail?.answers
                              .map((answer) => answer.selected_response)
                              .join(', ')
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedSummaryTab">
                  <h2>Responses</h2>
                  <div v-if="hasNoResponses(st)">There are no responses for this section.</div>
                  <div
                    v-for="questionDetail in mergeQuestionDetails(st)"
                    :key="questionDetail?.id"
                  >
                    <div v-if="!isComparisonQuestion(questionDetail)">
                      <div v-if="questionDetail?.answers[0].selected_response?.length > 0">
                        <Divider class="p-mb-4" />
                        <div v-html="questionDetail?.answers[0].selected_response"></div>
                      </div>

                      <div v-if="questionDetail?.not_selected_responses?.length > 0">
                        <Divider class="p-mb-4" />
                        <div v-html="questionDetail?.not_selected_responses"></div>
                      </div>
                    </div>

                    <div v-if="isComparisonQuestion(questionDetail)">
                      <template
                        v-if="questionDetail.choices.some((c) => c.selected_response !== null)"
                      >
                        <div
                          v-for="choice in questionDetail.choices"
                          :key="choice.selected_response"
                        >
                          <div v-html="choice.selected_response"></div>
                        </div>
                      </template>
                    </div>

                    <div
                      v-if="
                        questionDetail?.answers[0].external_notes ||
                        (questionDetail?.answers[0].confidential_notes &&
                          currentUser?.role === 'Superuser')
                      "
                      class="p-grid"
                    >
                      <div class="p-col-6">
                        <h4>External Notes</h4>
                        <p>{{ questionDetail?.answers[0].external_notes || 'No notes.' }}</p>
                      </div>
                      <div v-if="currentUser?.role === 'Superuser'" class="p-col-6">
                        <h4>Confidential Notes</h4>
                        <p>
                          {{ questionDetail?.answers[0].confidential_notes || 'No notes.' }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="
                        questionDetail?.answers[0].key_recommendations &&
                        questionDetail?.answers[0].key_recommendations.length > 0
                      "
                    >
                      <h4>Key Recommendations</h4>
                      <div
                        v-for="kr in questionDetail?.answers[0].key_recommendations"
                        :key="kr.id"
                      >
                        <p>{{ kr.text }}</p>
                        <ol>
                          <li v-for="ai in kr.action_items" :key="ai.id">
                            {{ ai.text }}
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div
                      v-if="
                        questionDetail?.answers[0].best_practices &&
                        questionDetail?.answers[0].best_practices.length > 0
                      "
                    >
                      <h4>Best Practices</h4>
                      <div v-for="bp in questionDetail?.answers[0].best_practices" :key="bp.id">
                        <p>{{ bp.text }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </template>
        </AccordionTab>
      </Accordion>
    </AccordionTab>
  </Accordion>

  <div v-if="selectedFileTab">
    <Card class="section-card p-mx-2 p-mt-2 p-mb-4">
      <template #content>
        <div class="p-grid">
          <h2 class="p-ml-2">Assessment Files</h2>

          <div class="p-col-12 p-mt-0">
            <div v-for="file in assessmentReport?.report?.report_data?.files" :key="file.id">
              <p class="p-m-0"><strong>Question:</strong> {{ file.question }}</p>
              <p v-if="file.value !== null && file.value !== ''" class="p-m-0">
                <strong>Note:</strong> {{ file.value }}
              </p>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  margin-bottom: 10px;
                  align-items: center;
                "
              >
                <p style="margin: 0 20px 0 0">
                  {{ getFileName(file.file) }}
                </p>
                <Button
                  @click="downloadFile(file.file)"
                  label="Download"
                  class="text-button-underline"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Button from 'primevue/button'
import Card from 'primevue/card'
import Divider from 'primevue/divider'
import SelectButton from 'primevue/selectbutton'
import WideAssessment from '@/components/cards/WideAssessment.vue'
import Loading from 'vue-loading-overlay'
import _ from 'lodash'

// Utils
import { calculateComparison, getFileName, getOperatorDisplay } from '@/utils/helpers'
import { printReportPDF, reportPDF, reportName } from '@/utils/pdfHelper'

export default {
  components: {
    Accordion,
    AccordionTab,
    Button,
    WideAssessment,
    Card,
    Divider,
    Loading,
    SelectButton,
  },
  props: {
    print: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      loadingReport: false,
      selectedDisplay: 'Show Summary',
    }
  },
  computed: {
    ...mapState('reports', ['assessmentReport']),
    ...mapState('users', ['currentUser']),
    peerDistrictRecommendation() {
      return this.assessmentReport?.report?.report_data?.peer_district_recommendation
    },
    displayOptions() {
      const tabs = ['Show Summary', 'Show Detailed (Q&A)']
      if (this.assessmentReport?.report?.report_data?.files?.length > 0) {
        tabs.push('Show Assessment Files')
      }
      if (this.assessmentReport?.report?.report_data?.peer_district_recommendation) {
        tabs.push('Show Peer Network Details')
      }
      return tabs
    },
    selectedSummaryTab() {
      return this.selectedDisplay === 'Show Summary'
    },
    selectedDetailedTab() {
      return this.selectedDisplay === 'Show Detailed (Q&A)'
    },
    selectedFileTab() {
      return this.selectedDisplay === 'Show Assessment Files'
    },
    selectedPeerNetworkTab() {
      return this.selectedDisplay === 'Show Peer Network Details'
    },
    showSections() {
      return (!this.selectedPeerNetworkTab && !this.selectedFileTab) || this.sectionReportMatches()
    },
    totalPoints() {
      return this.assessmentReport?.report?.report_data?.actual_score
    },
  },
  methods: {
    ...mapActions('reports', [
      'getAssessmentReport',
      'clearAllReportData',
      'uploadDistrictAssessmentReportFiles',
    ]),
    calculateComparison,
    getFileName,
    downloadFile(file) {
      window.open(file, '_blank')
    },
    isRollupQuestion(responseData) {
      const rollupType = this.assessmentReport?.question_types?.find(
        (t) => t.name === 'Rollup',
      )?.id
      return responseData.type === rollupType
    },
    isComparisonQuestion(responseData) {
      const rollupType = this.assessmentReport?.question_types?.find(
        (t) => t.name === 'Comparison',
      )?.id
      return responseData.type === rollupType
    },
    isMultiSelectQuestion(responseData) {
      const rollupType = this.assessmentReport?.question_types?.find(
        (t) => t.name === 'Multiple Select',
      )?.id
      return responseData.type === rollupType
    },
    sectionId(section) {
      return section.subsections[0].subsection_data.section
    },
    sectionReportMatches() {
      const matchedIds = this.peerDistrictRecommendation?.recommendation_section_matches?.map(
        (match) => match.receiving_section_report_id,
      )
      return this.peerDistrictRecommendation?.section_reports.filter((sectionReport) =>
        matchedIds.includes(sectionReport.id),
      )
    },
    sectionScoreData(section) {
      const sectionScores = this.assessmentReport?.report?.report_data?.section_scores || []
      return sectionScores.find((s) => s.id === this.sectionId(section))
    },
    prioritizeSection(section) {
      const sectionScoreData = this.sectionScoreData(section)
      const {
        actual_score: actual,
        total_score: total,
        score_threshold: threshold,
      } = sectionScoreData

      if (!threshold || !total) {
        return false
      }
      return threshold >= (100 * actual) / total
    },
    sectionHeader(section) {
      const sectionPriorityFlag = this.prioritizeSection(section) ? ' - HIGH PRIORITY' : ''
      return `${section.name} - ${this.addSubsectionScores(section)}${sectionPriorityFlag}`
    },
    sectionsByNames() {
      const sections = this.assessmentReport?.report?.subsections
      const realSections = _.map(sections, (section, i) => {
        if (
          sections[i - 1] &&
          sections[i - 1].subsection_data.section_name === section.subsection_data.section_name
        ) {
          return null
        } else {
          return section.subsection_data.section_name
        }
      })
      return realSections.filter((x) => x !== null)
    },
    sectionsAndSubsections() {
      const subsections = this.assessmentReport?.report?.subsections
      let sectionNames = this.sectionsByNames()

      if (this.selectedPeerNetworkTab) {
        const matchedSectionNames = this.sectionReportMatches().map((match) => match.section_name)
        sectionNames = sectionNames.filter((sectionName) =>
          matchedSectionNames.includes(sectionName),
        )
      }

      const sectionsObject = _.map(sectionNames, (sectionName) => {
        const subsectionsArray = subsections.filter((subsection) => {
          return subsection.subsection_data.section_name === sectionName
        })
        return { name: sectionName, subsections: subsectionsArray }
      })
      return sectionsObject
    },
    async runPdfGeneration(type) {
      const report = reportPDF(this.assessmentReport, type)
      const name = reportName(this.assessmentReport, type, this.currentUser)

      // save newly-generated report
      report.getBase64((reportData) => {
        const reports = {}
        let reportType = ''
        if (type === 'Detailed') reportType = 'detailed'
        if (type === 'Summary') reportType = 'summary'
        if (type === 'Admin Detailed') reportType = 'admin_detailed'
        reports[reportType] = { report: reportData, name: name + '.pdf' }

        this.uploadDistrictAssessmentReportFiles({
          districtAssessmentId: this.assessmentReport.report.report_data.district_assessment,
          reports: reports,
        })
      })

      await printReportPDF(report, name)
      this.loadingReport = false
    },
    addSubsectionScores(section) {
      let totalSectionScore = 0
      _.forEach(section.subsections, (subsection) => {
        totalSectionScore = totalSectionScore + subsection.subsection_data.actual_score
      })

      return totalSectionScore
    },
    hasNoResponses(section) {
      let empty = true

      _.forEach(section.question_responses, (r) => {
        if (_.some(r.answers, (a) => a.selected_response?.length > 0)) {
          empty = false
        }
      })

      if (_.some(section.not_selected_responses, (r) => r.not_selected_responses?.length > 0)) {
        empty = false
      }

      return empty
    },
    mergeQuestionDetails(section) {
      // Check if there are question_responses that also have not_selected_responses for the same question
      // Group these together for display
      const mergedQuestionDetails = _(section.question_responses)
        .keyBy('id') // Create a dictionary of the 1st array using the id as the key
        .merge(_.keyBy(section.not_selected_responses, 'id')) // Create a dictionary of the 2nd array using the id as the key, replacing duplicates
        .values() // Convert dictionary to array
        .value()

      // Group and check for all comparison questions. One or both of the choices
      // could have responses configured, and we need to display both fields for the detailed report.
      const comparisonDisplays = []
      if (section.question_responses.some((q) => q.type === 12)) {
        // Pull the pairs of comparison questions, formatting just one object for display
        const comparisonQuestions = _.filter(section.question_responses, (q) => q.type === 12)
        const groups = _.groupBy(comparisonQuestions, (q) => q.id)

        _.forEach(_.keys(groups), (k) => {
          const first = groups[k][0]
          const second = groups[k][1]

          // If either choice has a response configured, group them together
          const operatorDetails = _.find([first, second], (q) => q.operator !== undefined)
          const operator = getOperatorDisplay(operatorDetails.operator)

          first.choices = [
            {
              text: first.answers[0].choice,
              value: first.answers[0].value,
              selected_response: first.answers[0].selected_response,
            },
            {
              text: second.answers[0].choice,
              value: second.answers[0].value,
              selected_response: second.answers[0].selected_response,
            },
          ]
          first.operator = operator
          comparisonDisplays.push(first)
        })

        // Replace the first instance of the comparison question with the grouped display, and remove the second
        _.forEach(comparisonDisplays, (c) => {
          const pair = _.filter(mergedQuestionDetails, (q) => q.id === c.id)
          const index = _.findIndex(mergedQuestionDetails, (q) => q.id === c.id)
          mergedQuestionDetails.splice(index, 1, c)

          // If both choices have a response configured, remove the second instance
          if (pair.length > 1) {
            const secondIndex = _.findLastIndex(mergedQuestionDetails, (q) => q.id === c.id)
            mergedQuestionDetails.splice(secondIndex, 1)
          }
        })
      }

      return mergedQuestionDetails
    },
  },
  async mounted() {
    this.loading = true
    this.getAssessmentReport({ districtAssessmentId: this.$route.params.id, $toast: this.$toast })
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false

        // Redirect back to dashboard if report is not found
        if (this.currentUser?.role === 'School User') {
          this.$router.push('/')
        } else {
          this.$router.push('/assessments')
        }
      })
  },
  unmounted() {
    this.clearAllReportData()
  },
  updated() {
    if (this.print !== null) {
      this.loadingReport = true
      this.runPdfGeneration(this.print)
    }
  },
}
</script>

<style>
.p-selectbutton .p-button.p-highlight {
  background: #326195;
  border-color: #326195;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #326195;
  border-color: #326195;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-label {
  color: #fff;
}
</style>
