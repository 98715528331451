<template>
  <metainfo></metainfo>
  <Toast position="top-right" group="tr" />
  <loading
    v-model:active="globalLoadingIndicator"
    :can-cancel="false"
    :lock-scroll="true"
    :height="70"
    :width="70"
    loader="bars"
  />

  <router-view />
</template>

<script>
import { mapState } from 'vuex'
import Toast from 'primevue/toast'
import Loading from 'vue-loading-overlay'

export default {
  components: {
    Toast,
    Loading,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'SCALE | My Lunchbox',
    // all titles will be injected into this template
    titleTemplate: '%s | My Lunchbox',
  },
  data() {
    return {
      isAuthenticated: false,
    }
  },
  computed: {
    ...mapState(['globalLoadingIndicator']),
  },
}
</script>
