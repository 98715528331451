import ax from '@/store/utils/axiosConfig'
// import router from '../../router'

export default {
  namespaced: true,
  state: {
    districtReports: {},
    assessmentReport: {},
  },
  mutations: {
    updateDistrictReports(state, payload) {
      state.districtReports = payload
    },
    updateAssessmentReport(state, payload) {
      state.assessmentReport = payload
    },
    clearAllReportData(state) {
      // this is used to reset data for users who log out or whose authentication expires
      state.districtReport = {}
    },
  },
  actions: {
    async getDistrictReports({ commit }, { districtId, $toast }) {
      try {
        // Cumulative report for district with comparisons of other districts
        const { data } = await ax.get(`/district-reports/${districtId}`)
        commit('updateDistrictReports', data)
      } catch (error) {
        console.log('Error getting district reports:', error)

        $toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Error getting district reports',
          detail: 'Please try again.',
          life: 5000,
        })
      }
    },
    async getAssessmentReport({ commit, rootState }, { districtAssessmentId, $toast }) {
      try {
        const { data } = await ax.get(`/assessment-reports/${districtAssessmentId}/`)
        commit('updateAssessmentReport', data)
      } catch (error) {
        console.log('Error getting report:', error)

        $toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Error getting report',
          detail: 'Please try again.',
          life: 5000,
        })

        throw new Error('Error getting report')
      }
    },
    async getAssessmentReportDownload({ commit, rootState }, { type, id, $toast }) {
      try {
        const { data } = await ax.get(`/assessment-reports/${id}/download/?type=${type}`)

        // TODO: cleanup:
        if (type === 'pdf') {
          const url = `data:application/pdf;base64,${data}`
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'assessment-detailed-report.pdf')
          document.body.appendChild(link)
          link.click()
        } else {
          const url = `data:application/pdf;base64,${data}`
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'assessment-summary-report.pdf')
          document.body.appendChild(link)
          link.click()
        }
      } catch (err) {
        console.log('Error getting report:', err)

        $toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Error getting reports',
          detail: 'Please try again.',
          life: 5000,
        })
      }
    },
    async createDistrictAssessmentReport({ commit }, { districtAssessmentId, $toast }) {
      try {
        const { data } = await ax.get(`/assessment-reports/${districtAssessmentId}/create/`)
        return data
      } catch (error) {
        console.log('Error creating report:', error)

        $toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Error creating report',
          detail: 'Please try again.',
          life: 5000,
        })

        return null
      }
    },
    async uploadDistrictAssessmentReportFiles({ commit }, payload) {
      try {
        const districtAssessmentId = payload.districtAssessmentId
        const reports = payload.reports
        await ax.post(
          `/assessments/district-assessments/${districtAssessmentId}/reports/`,
          reports,
        )
      } catch (err) {
        console.log('Error uploading report:', err)
      }
    },
    clearAllReportData({ commit }) {
      commit('clearAllReportData')
    },
  },
}
