import state, { initialAssessment } from './state'
import actions from './actions'
import createMutations from './createMutations'
import router from '../../router'

export default {
  namespaced: true,
  state,
  getters: {
    currentSubSection(state) {
      return state.currentAssessment.sections[state.currentSubSectionInfo?.sectionIndex]
        .subsections[state.currentSubSectionInfo?.subIndex]
    },
  },
  mutations: {
    updateAssessments(state, payload) {
      state.assessments = payload
    },
    updateQuestionTypes(state, payload) {
      state.questionTypes = payload
    },
    setAssessmentDetails(state, payload) {
      state.assessmentDetails = payload
    },
    updateAssessmentQuestion(state, payload) {
      state.currentQuestionDetails = payload
      state.currentQuestionDetails.queryParams = {
        sec: payload.sec || 1,
        sub: payload.sub || 1,
        q: payload.question?.order || payload.q || 1,
      }
    },
    updateSelectedDistrict(state, payload) {
      state.selectedDistrict = payload
    },
    updateDistrictAssessments(state, payload) {
      state.districtAssessments = payload
    },
    updateNestedQuestionsList(state, payload) {
      state.nestedQuestionsList = payload
    },

    ...createMutations,
    clearAssessment(state, shouldNavigate) {
      state.currentAssessment = {
        ...initialAssessment,
      }
      state.showQuestions = false
      state.currentStep = 1

      if (shouldNavigate) {
        router.push('/assessments')
      }
    },

    clearAllAssessmentData(state) {
      // TODO: change this to pull in default state object
      // this is used to reset data for users who log out or whose authentication expires
      state.assessments = []
      state.currentSubSectionInfo = {
        sectionIndex: 0,
        subIndex: 0,
      }
      state.currentAssessment = {
        ...initialAssessment,
      }
    },
  },
  actions,
}
