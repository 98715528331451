<template>
  <div class="p-grid assessments-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../../assets/icons/books.png" class="p-mr-3" />
      <h1>Assessments</h1>
    </div>
    <div class="p-col-12 p-d-flex p-jc-between p-ai-center">
      <div>
        <Dropdown
          v-model="district"
          @change="selectedDistrict($event)"
          @filter="filterByDistrict($event)"
          :options="filteredDistricts"
          :showClear="true"
          optionLabel="name"
          placeholder="Filter by District"
          class="full-width-input"
          :filter="true"
          filterPlaceholder="Search Districts"
          dataKey="id"
        />
      </div>
      <div class="p-d-flex p-ai-center">
        <!-- <Button label="Assign to District" class="text-button-underline p-mr-3" /> -->
        <Button
          v-if="$route.name !== 'district-report'"
          @click="viewDistrictReport"
          :disabled="!district"
          label="View District Report"
          class="text-button-underline p-mr-3"
        />
        <Button
          v-else
          @click="$router.push('/assessments')"
          label="All Assessments"
          class="text-button-underline p-mr-3"
        />
      </div>
    </div>

    <router-view :district="district" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

export default {
  components: {
    Button,
    Dropdown,
  },
  data() {
    return {
      district: '',
      filteredDistricts: [],
    }
  },
  methods: {
    ...mapMutations('assessments', ['clearAllAssessmentData']),
    ...mapActions(['getDistricts']),
    ...mapActions('assessments', ['saveSelectedDistrict']),
    ...mapActions('reports', ['getDistrictReports']),
    async filterByDistrict(e) {
      this.filteredDistricts = await this.getDistricts({ query: e.value })
    },
    async selectedDistrict(e) {
      this.saveSelectedDistrict({ district: e.value, route: this.$route.meta.route })
    },
    viewDistrictReport() {
      this.$router.push('/assessments/district-report')
    },
  },
  unmounted() {
    this.clearAllAssessmentData()
  },
}
</script>
