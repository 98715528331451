<template>
  <AuthScreenLayout>
    <div class="p-col-5">
      <p>E-MAIL*</p>
    </div>
    <div class="p-col-7">
      <InputText v-model="email" :class="[v$.email?.$error ? 'p-invalid' : '']" type="text" />
    </div>
    <div class="p-col-7">
      <Button
        :disabled="!email"
        @click="submit"
        label="Submit"
        class="p-button-text underline-button"
      />
    </div>
  </AuthScreenLayout>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import AuthScreenLayout from '@/layouts/AuthScreenLayout.vue'
import { mapActions } from 'vuex'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    AuthScreenLayout,
    InputText,
    Button,
  },
  data() {
    return {
      email: '',
    }
  },
  validations() {
    return {
      email: { required, email },
    }
  },
  methods: {
    ...mapActions('users', ['forgotPassword']),
    submit() {
      this.v$.$touch()
      if (this.v$.$error) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Form Error',
          detail: 'Please check that the email address is valid.',
          life: 5000,
        })
        return
      }

      this.forgotPassword({ email: this.email, $toast: this.$toast })
    },
  },
}
</script>
