import axios from 'axios'

import store from '../'
import Auth from './Auth'

const authUrls = ['/auth/login/', '/api/token/refresh/', '/auth/password/reset/']

const ax = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

const resfreshAccessToken = async () => {
  try {
    const res = await ax.post('/api/token/refresh/', {
      refresh: Auth.refreshToken,
    })
    const access = res.data.access
    Auth.accessToken = access

    return access
  } catch (e) {
    return null
  }
}

ax.interceptors.request.use((config) => {
  // Don't add the token if you're using any of the auth urls
  if (authUrls.includes(config.url)) {
    return config
  }

  const accessToken = Auth.accessToken
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  if (config.data?.answer?.file) {
    config.headers['Content-Type'] = 'multipart/form-data'
    const file = config.data.answer.file
    delete config.data.answer.file

    const formData = new FormData()
    formData.append('file', file)
    formData.append('data', JSON.stringify(config.data))
    config.data = formData
  } else {
    config.headers['Content-Type'] = 'application/json'
  }
  return config
})

ax.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config: originalRequest, response } = error

    if (
      originalRequest &&
      originalRequest.url !== '/api/token/refresh/' &&
      !originalRequest._retry &&
      response?.status === 401
    ) {
      originalRequest._retry = true
      const access = await resfreshAccessToken()
      if (access) {
        ax.defaults.headers.common.Authorization = `Bearer ${access}`
        return ax(originalRequest)
      } else {
        Auth.logout()
        store.dispatch('users/logout')
      }
    }

    return Promise.reject(error)
  },
)

export default ax
