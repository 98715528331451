import { createApp } from 'vue'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
// import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import App from './App.vue'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
// TODO: move to sass files
import 'primeflex/primeflex.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import '@vueform/slider/themes/default.css'
import VueGtag from 'vue-gtag'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'

import '@/sass/index.sass'

const app = createApp(App)
// const metaManager = createMetaManager()

app.use(store)
app.use(router)
app.use(ToastService)
app.use(PrimeVue, { ripple: true })
// app.use(metaManager)
// app.use(metaPlugin)
app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
  pageTrackerScreenviewEnabled: true,
})
app.mount('#app')
