import Dashboard from '../views/Dashboard.vue'
import Users from '../views/Users.vue'
import Settings from '../views/Settings.vue'
import Register from '../views/auth/Registration.vue'

import AssessmentScreensWrapper from '../views/assessments/AssessmentScreensWrapper.vue'
import Assessments from '../views/assessments/Assessments.vue'
import TakeAssessment from '../views/assessments/TakeAssessment.vue'
import DistrictReport from '../views/reports/DistrictReport.vue'
import AssessmentReport from '../views/reports/AssessmentReport.vue'

import CreateAssessmentWrapper from '../views/assessments/CreateAssessmentWrapper.vue'
import CreateAssessment from '../views/assessments/CreateAssessment.vue'
import PreviewSubSection from '../views/assessments/PreviewSubSection.vue'

const siteName = 'SCALE'

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      allowedRoles: ['School User', 'District Admin', 'Superuser'],
      title: `${siteName} | Dashboard`,
    },
  },
  {
    path: '/assessments',
    name: 'assessments',
    component: AssessmentScreensWrapper,
    children: [
      {
        path: '',
        component: Assessments,
        meta: {
          allowedRoles: ['District Admin', 'Superuser', 'School User'],
          title: `${siteName} | Assessments`,
        },
      },
      {
        path: 'district-report',
        name: 'district-report',
        component: DistrictReport,
        meta: {
          allowedRoles: ['District Admin', 'Superuser'],
          route: 'district-report',
          title: `${siteName} | District Report`,
        },
      },
      {
        path: ':id/report',
        name: 'assessment-report',
        component: AssessmentReport,
        meta: {
          allowedRoles: ['School User', 'District Admin', 'Superuser'],
          title: `${siteName} | Assessment Report`,
        },
      },
    ],
  },
  {
    path: '/assessment-creator',
    name: 'assessment-creator',
    component: CreateAssessmentWrapper,
    children: [
      {
        path: '',
        name: 'create-assessment',
        component: CreateAssessment,
        meta: {
          allowedRoles: ['District Admin', 'Superuser'],
          title: `${siteName} | Create Assessment`,
        },
      },
      {
        path: ':id/edit',
        name: 'edit-assessment',
        component: CreateAssessment,
        meta: {
          allowedRoles: ['District Admin', 'Superuser'],
          title: `${siteName} | Edit Assessment`,
        },
      },
      {
        path: ':id/subsection/:subId/preview',
        name: 'preview-subsection',
        component: PreviewSubSection,
        meta: {
          allowedRoles: ['District Admin', 'Superuser'],
          title: `${siteName} | Preview Subsection`,
        },
      },
    ],
  },
  {
    path: '/assessments/:id/take',
    name: 'assessment-take',
    component: TakeAssessment,
    meta: {
      allowedRoles: ['School User', 'District Admin', 'Superuser'],
      title: `${siteName} | Take Assessment`,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      allowedRoles: ['District Admin', 'Superuser'],
      title: `${siteName} | Users`,
    },
  },
  {
    path: '/users/registration',
    name: 'users-registration',
    component: Register,
    meta: {
      allowedRoles: ['District Admin', 'Superuser'],
      title: `${siteName} | User Registration`,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      allowedRoles: ['School User', 'District Admin', 'Superuser'],
      title: `${siteName} | Settings`,
    },
  },
]
