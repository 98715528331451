<template>
  <!-- TODO: change to see assessment reports from just one district -->
  <div class="p-grid assessments-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <h1>District Report</h1>
    </div>

    <hr class="p-col-12" />

    <div v-if="selectedDistrict" class="p-col-9">
      <DataTable :value="districtReports" responsiveLayout="scroll" dataKey="email">
        <Column field="assessment" header="Assessment"></Column>
        <Column field="total_score" header="Total Points Available"></Column>
        <!-- <Column field="answers" header="District Answers"></Column> -->
        <Column field="actual_score" header="Assessment Score"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
  components: {
    DataTable,
    Column,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('assessments', ['selectedDistrict']),
    ...mapState('reports', ['districtReports']),
  },
  methods: {
    ...mapActions('reports', ['getDistrictReports']),
  },
  async mounted() {
    this.getDistrictReports({ districtId: this.selectedDistrict?.id, $toast: this.$toast })
  },
}
</script>

<style lang="sass" scoped>
.blue-orange-button
  width: 231.71px

hr
  padding: 0
  margin-top: 20px
  border-color: #fefefe
</style>
