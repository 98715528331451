<template>
  <div v-for="(question, i) in filteredQuestions" :key="`${question.id}-${i}`" class="p-grid">
    <div class="p-lg-6 p-md-6 p-sm-12">
      <div>
        <label for="type">QUESTION TYPE</label><br />
        <Dropdown
          :modelValue="question.type"
          @change="(e) => updateQuestion({ question, qIndex: i, field: 'type', value: e.value })"
          :options="questionTypes"
          optionLabel="name"
          class="full-width-input"
        />
      </div>
      <div>
        <label for="text">TITLE</label><br />
        <Textarea
          :value="question.text"
          @input="
            (e) => updateQuestion({ question, qIndex: i, field: 'text', value: e.target.value })
          "
          type="text"
          class="full-width-textarea"
        />
      </div>
      <div>
        <label for="description">DESCRIPTION</label><br />
        <QuillEditor
          :value="question.description"
          :options="editorOptions"
          @change="onEditorChange($event, { question, qIndex: i })"
        />
      </div>
      <div>
        <label for="required">REQUIRED</label><br />
        <Checkbox v-model="question.required" :binary="true" name="required" />
      </div>
      <div v-if="question?.type?.name === 'Rollup'">
        <Divider />

        <label class="block" for="rollup-format">
          ROLLUP FORMAT<br />
          <small class="block"
            ><em
              >"Numeric" values will display input as a count, with the percent of the total
              calculated and displayed alongside. "Percent" values will display input as a percent
              alone.</em
            ></small
          >
        </label>

        <label class="ltr">
          <RadioButton
            value="numeric"
            :modelValue="question.rollup_config?.display_format"
            @change="
              (e) =>
                updateQuestion({
                  question,
                  qIndex: i,
                  field: 'rollup_config',
                  value: { ...question.rollup_config, display_format: 'numeric' },
                })
            "
          />
          Numeric
        </label>
        <label class="ltr">
          <RadioButton
            value="percent"
            :modelValue="question.rollup_config?.display_format"
            @change="
              (e) =>
                updateQuestion({
                  question,
                  qIndex: i,
                  field: 'rollup_config',
                  value: { ...question.rollup_config, display_format: 'percent' },
                })
            "
          />
          Percent
        </label>

        <label class="block" for="rollup-total">
          ROLLUP TOTAL<br />
          <small class="block"
            ><em
              >"Fixed" total provides a fixed total across assessments to which sub-questions must
              sum for this question. "Input" total allows the assessment-taker to provide their own
              total value (as an answer input for the selected sub-question), to which other
              sub-questions must sum. Leave both options blank to auto-calculate total.</em
            ></small
          >
        </label>

        <label>
          Fixed
          <InputText
            :value="question.rollup_config?.fixed_total"
            @change="
              (e) =>
                updateQuestion({
                  question,
                  qIndex: i,
                  field: 'rollup_config',
                  value: { ...question.rollup_config, fixed_total: e.target.value },
                })
            "
            type="number"
            class="half-width-input"
          />
        </label>
        <span class="block">OR</span>
        <label>
          Input
          <Dropdown
            v-if="question.answers.length > 0"
            placeholder="Select Sub-Question"
            :modelValue="rollupConfigChoiceSelection(question.rollup_config?.total_choice)"
            @change="
              (e) =>
                updateQuestion({
                  question,
                  qIndex: i,
                  field: 'rollup_config',
                  value: { ...question.rollup_config, total_choice: e.value },
                })
            "
            :options="rollupConfigChoiceOptions(question.answers)"
            optionLabel="text"
            optionValue="optionId"
            class="half-width-input"
          />
        </label>
      </div>
      <div v-if="question?.type?.name === 'Comparison'">
        <Divider />

        <label class="block" for="operator">
          COMPARISON OPERATOR<br />
          <small class="block"
            ><em>"Numeric" answers will be compared by the selected operator.</em></small
          >
        </label>
        <Dropdown
          placeholder="Select Operator"
          :modelValue="question.comparison_config?.operator"
          @change="
            (e) =>
              updateQuestion({
                question,
                qIndex: i,
                field: 'comparison_config',
                value: { operator: e.value },
              })
          "
          :options="['Divide', 'Multiply', 'Add', 'Subtract']"
          class="half-width-input"
        />
      </div>
      <div class="p-d-flex">
        <Button
          label="Delete Question"
          icon="pi pi-trash"
          class="p-button-text p-mt-6"
          @click="() => removeQuestion({ question, qIndex: i })"
        />
      </div>
    </div>
    <div class="p-lg-5 p-md-6 p-sm-12 p-xl-offset-1">
      <AddAnswers :question="question" :type="question.type" :qIndex="i" />
    </div>

    <Divider />
  </div>
  <div class="p-d-flex">
    <Button
      label="Add Question"
      icon="pi pi-plus-circle"
      class="p-button-text"
      @click="() => addQuestion({ ...currentSubSectionInfo })"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Textarea from 'primevue/textarea'
import { quillEditor as QuillEditor } from 'vue3-quill'

import AddAnswers from '@/components/assessment-wizard/AddAnswers.vue'

export default {
  components: {
    AddAnswers,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    InputText,
    QuillEditor,
    RadioButton,
    Textarea,
  },
  data() {
    return {
      pointsRange: [-4, -3, -2, -1, 0, 1, 2, 3, 4].map((val) => ({ name: val, code: val })),
      editorOptions: {
        placeholder: 'Enter Response Text',
        modules: {
          toolbar: [['link', 'image']],
        },
      },
    }
  },
  computed: {
    ...mapState('assessments', ['questionTypes', 'currentSubSectionInfo', 'currentQuestions']),
    ...mapGetters('assessments', ['currentSubSection']),
    filteredQuestions() {
      return this.currentQuestions.filter((question) => {
        return !question.deleted
      })
    },
  },
  watch: {
    currentQuestions() {
      this.$forceUpdate()
    },
  },
  methods: {
    ...mapMutations('assessments', [
      'addQuestion',
      'removeQuestion',
      'updateQuestion',
      'clearCurrentQuestions',
    ]),
    ...mapActions('assessments', ['getQuestionTypes', 'getSubSectionQuestions']),
    onEditorChange({ quill, html, text }, { question, qIndex }) {
      this.updateQuestion({ question, qIndex, field: 'description', value: html })
    },
    rollupConfigChoiceOptions(answers) {
      return answers
        .filter((a) => {
          return !a.deleted
        })
        .map((a) => {
          a.optionId = a.id || a.tempId
          return a
        })
    },
    rollupConfigChoiceSelection(configChoice) {
      if (!configChoice) {
        return null
      }
      if (typeof configChoice === 'number') {
        return configChoice
      }

      return configChoice.id || configChoice.tempId
    },
  },
  mounted() {
    this.getQuestionTypes({ $toast: this.$toast })
    this.getSubSectionQuestions({ sub: this.currentSubSection?.id, $toast: this.$toast })
  },
  unmounted() {
    this.clearCurrentQuestions()
  },
}
</script>
