import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ax from './utils/axiosConfig'
import users from './users'
import assessments from './assessments'
import reports from './reports'

export default createStore({
  state: () => ({
    districts: [],
    titles: [],
    roles: [],
    globalLoadingIndicator: false,
  }),
  mutations: {
    setLoadingIndicator(state, value) {
      state.globalLoadingIndicator = value
    },
    updateDistricts(state, payload) {
      state.districts = payload
    },
    updateTitles(state, payload) {
      state.titles = payload
    },
    updateRoles(state, payload) {
      state.roles = payload
    },
    clearGlobalData(state) {
      state.districts = []
      state.titles = []
      state.roles = []
    },
  },
  actions: {
    async getDistricts({ commit }, { query = null }) {
      const search = query ? `?search=${query}` : ''
      const res = await ax.get(`/users/districts${search}`)
      return res.data
    },
    async getTitles({ commit }) {
      const res = await ax.get('/users/titles')
      commit('updateTitles', res.data)
    },
    async getRoles({ commit }) {
      const res = await ax.get('/users/roles')
      commit('updateRoles', res.data)
    },
    clearAllData({ commit }) {
      // This resets all vuex state back to initial
      commit('users/clearAllUserData', null, { root: true })
      commit('assessments/clearAllAssessmentData', null, { root: true })
      commit('reports/clearAllReportData', null, { root: true })
      commit('clearGlobalData')
    },
  },
  modules: {
    users,
    assessments,
    reports,
  },
  plugins: [createPersistedState()],
})
