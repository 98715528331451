export const templateSection = {
  name: '',
  description: '',
  order: 1,
  subsections: [],
}

export const templateSubSection = {
  name: '',
  description: '',
  order: 1,
  questions: [],
}

const templateAnswer = {
  tempId: null, // set to Date.now() on instantiation
  text: null,
  weight: null,
  response: '',
  send_to: null,
}

const templateQuestion = {
  type: null,
  text: '',
  description: '',
  order: 1,
  answers: [JSON.parse(JSON.stringify(templateAnswer))],
}

export default {
  updateSetupData(state, payload) {
    state.currentAssessment.setupData = {
      ...state.currentAssessment.setupData,
      ...payload,
    }
  },
  setCurrentQuestions(state, payload) {
    state.currentQuestions = payload
  },
  setPreviewQuestions(state, payload) {
    state.previewQuestions = payload
  },
  setSections(state, payload) {
    state.currentAssessment.sections = payload
  },
  updateSection(state, payload) {
    const index = state.currentAssessment.sections.findIndex(
      (section) => section.id === payload.id,
    )
    if (index === -1) {
      state.currentAssessment.sections.push(payload)
    } else {
      state.currentAssessment.sections[index] = payload
    }
  },
  removeSection(state, sectionIndex) {
    state.currentAssessment.sections.splice(sectionIndex, 1)
  },
  selectSubSection(state, { sectionIndex, subIndex }) {
    state.currentSubSectionInfo.sectionIndex = sectionIndex
    state.currentSubSectionInfo.subIndex = subIndex
    state.showQuestions = true
  },
  addSubSection(state, sectionIndex) {
    state.currentAssessment.sections[sectionIndex].subsections.push(
      JSON.parse(JSON.stringify(templateSubSection)),
    )
  },
  removeSubSection(state, { sectionIndex, subIndex }) {
    state.currentAssessment.sections[sectionIndex].subsections.splice(subIndex, 1)
  },
  updateSubSection(state, { sectionIndex, data }) {
    const index = state.currentAssessment.sections[sectionIndex].subsections.findIndex(
      (sub) => sub.id === data.id,
    )
    if (index === -1) {
      state.currentAssessment.sections[sectionIndex].subsections.push(data)
    } else {
      state.currentAssessment.sections[sectionIndex].subsections[index] = data
    }
  },
  toggleShowQuestions(state, value) {
    // Need this here because buttons are in the CreateAssessment component
    // and we need to access the showQuestions boolean in other components
    state.showQuestions = value
  },
  addQuestion(state) {
    state.currentQuestions.push(JSON.parse(JSON.stringify(templateQuestion)))
  },
  removeQuestion(state, { qIndex }) {
    // this needs to soft delete if there is an id so the back-end can properly
    // delete the question
    const question = state.currentQuestions[qIndex]
    if (question?.id) {
      question.deleted = true
    } else {
      state.currentQuestions.splice(qIndex, 1)
    }
  },
  clearCurrentQuestions(state) {
    state.currentQuestions = []
  },
  updateQuestion(state, { qIndex, field, value }) {
    if (field === 'type') {
      // need to mark current answers as deleted
      state.currentQuestions[qIndex].answers?.forEach((answer, aIndex) => {
        if (answer?.id) {
          answer.deleted = true
        } else {
          state.currentQuestions[qIndex].answers.splice(aIndex, 1)
        }
      })

      // Check the question type to see if we need to add any answers
      // automatically
      if (value.name === 'Dichotomous') {
        state.currentQuestions[qIndex].answers = [
          ...state.currentQuestions[qIndex].answers.filter((ans) => ans.deleted),
          {
            ...templateAnswer,
            tempId: Date.now(),
            text: 'True',
          },
          {
            ...templateAnswer,
            tempId: Date.now(),
            text: 'False',
          },
        ]
      } else if (value.name === 'Rollup') {
        state.currentQuestions[qIndex].answers = [
          ...state.currentQuestions[qIndex].answers.filter((ans) => ans.deleted),
          {
            ...templateAnswer,
            tempId: Date.now(),
            text: 'Sub-question #1',
            scoring: { weights: '{"0": 0}' },
          },
          {
            ...templateAnswer,
            tempId: Date.now() + 1,
            text: 'Sub-question #2',
            scoring: { weights: '{"0": 0}' },
          },
        ]
      } else if (value.name === 'Comparison') {
        state.currentQuestions[qIndex].answers = [
          ...state.currentQuestions[qIndex].answers.filter((ans) => ans.deleted),
          {
            ...templateAnswer,
            tempId: Date.now(),
            text: '',
          },
          {
            ...templateAnswer,
            tempId: Date.now() + 1,
            text: '',
          },
        ]
      } else if (value.name === 'File') {
        state.currentQuestions[qIndex].answers = [
          ...state.currentQuestions[qIndex].answers.filter((ans) => ans.deleted),
          {
            ...templateAnswer,
            tempId: Date.now(),
            text: '',
          },
        ]
      } else {
        state.currentQuestions[qIndex].answers = [
          ...state.currentQuestions[qIndex].answers.filter((ans) => ans.deleted),
          {
            ...templateAnswer,
            tempId: Date.now(),
          },
        ]
      }
    }

    state.currentQuestions[qIndex][field] = value
  },
  addAnswer(state, { qIndex }) {
    // this is used for multiple choice questions where more than one answer object is necessary
    state.currentQuestions[qIndex].answers.push({ ...templateAnswer, tempId: Date.now() })
  },
  removeAnswer(state, { qIndex, aIndex }) {
    // this is used for multiple choice questions where more than one answer object is necessary
    const answer = state.currentQuestions[qIndex].answers[aIndex]
    if (answer?.id) {
      answer.deleted = true
    } else {
      state.currentQuestions[qIndex].answers.splice(aIndex, 1)
    }
  },
  updateAnswer(state, { qIndex, aIndex, field, value }) {
    // state.currentAssessment.sections[sectionIndex].subsections[subIndex].questions[qIndex].answers[aIndex][field] = value
    state.currentQuestions[qIndex].answers[aIndex][field] = value
  },
  updateCurrentStep(state, step) {
    state.currentStep = step
  },
}
