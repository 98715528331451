export const calculateComparison = (operator, first, second) => {
  switch (operator) {
    case '/':
    case 'Divide':
      return (Number(first) / Number(second)).toFixed(2)
    case '*':
    case 'Multiply':
      return Number(first) * Number(second)
    case '+':
    case 'Add':
      return Number(first) + Number(second)
    case '-':
    case 'Subtract':
      return Number(first) - Number(second)
    default:
      return ''
  }
}

export const getOperatorDisplay = (operator) => {
  if (operator === 'Divide' || operator === '/') return '/'
  if (operator === 'Multiply' || operator === '*') return '*'
  if (operator === 'Add' || operator === '+') return '+'
  if (operator === 'Subtract' || operator === '-') return '-'
}

export const getFileName = (path) => {
  return path ? path.substring(path.lastIndexOf('/') + 1) : ''
}

export const debounce = (func) => {
  let timeout
  return (params) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(params)
    }, 500)
  }
}
