<template>
  <div class="card wide-card p-mb-4 p-mt-4">
    <div class="card-body p-p-0 p-d-flex">
      <div :class="['dark-blue-section', isReport ? 'p-col-3' : 'p-col-4', 'p-ai-center']">
        <div class="p-grid">
          <div class="p-col-8">
            <h3 class="card-title p-mb-4">
              {{ assessment?.name || assessment?.assessment_name }}
            </h3>
            <!-- <div v-if="filterType !== 'all'" class="progress">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div> -->
          </div>
          <!-- <div v-if="filterType !== 'all'" class="p-col-4">
            <p class="percentage">25<span class="percent-symbol">%</span></p>
          </div> -->
        </div>
      </div>
      <div :class="['right-section', 'p-grid', isReport ? 'p-col-9' : 'p-col-8', 'p-d-flex']">
        <section v-if="filterType !== 'all' && filterType !== 'hybrid'" class="p-col-2 p-ml-5">
          <template v-if="isReport">
            <h5 class="card-title">Completed On</h5>
            <p>{{ completedAt }}</p>
          </template>
          <template v-else-if="completedAt !== null">
            <h5 class="card-title">Completed On</h5>
            <p>{{ completedAt }}</p>
          </template>
          <template v-else>
            <h5 class="card-title">Completion</h5>
            <p>{{ filterType === 'completed' ? '100%' : 'NA' }}</p>
          </template>
        </section>
        <section class="p-col-2 p-ml-5">
          <h5 class="card-title">Type</h5>
          <p>{{ assessment?.get_type_display || assessment?.type }}</p>
        </section>
        <section v-if="assessment?.get_districts_names && !isReport" class="p-col-3">
          <h5 class="card-title">District(s)</h5>
          <p>{{ assessment?.get_districts_names?.toString().replace(',', ', ') }}</p>
        </section>
        <section v-if="currentUser.role === 'Superuser' && assessment?.completed" class="p-col-3">
          <h5 class="card-title">District</h5>
          <p>{{ assessment?.district }}</p>
        </section>
        <section v-if="currentUser.role === 'Superuser' && isReport && report" class="p-col-3">
          <h5 class="card-title">District</h5>
          <p>{{ report.taken_by.user_profile.district.name }}</p>
        </section>
        <section v-if="isReport" class="p-col-3">
          <h5 class="card-title">Total Score</h5>
          <p>{{ percentageScore }}</p>
        </section>
        <div class="card-icons">
          <i
            v-if="filterType === 'completed'"
            @click="() => toggleDownloadDialog(assessment)"
            class="pi pi-download"
          ></i>
          <!-- <i v-if="!isReport" class="pi pi-copy"></i> -->
          <i
            v-if="!isReport && filterType !== 'completed'"
            @click="
              $router.push(
                `/assessment-creator/${assessment?.assessment?.id || assessment?.id}/edit`,
              )
            "
            class="pi pi-pencil"
          ></i>
        </div>
        <div v-if="!isReport" class="card-buttons">
          <!-- <Button label="Preview" class="text-button-underline p-mr-3" /> -->
          <Button
            v-if="filterType === 'completed'"
            @click="$router.push(`/assessments/${assessment?.id}/report`)"
            label="View Report"
            class="text-button-underline p-mr-3"
          />
          <Button
            v-else-if="filterType === 'inprogress'"
            @click="$router.push(`/assessments/${assessment?.id}/take`)"
            label="Complete Assessment"
            class="text-button-underline p-mr-3"
          />
          <Button
            v-else-if="filterType === 'hybrid'"
            @click="() => openAssignmentDialog(assessment)"
            label="Assign Assessment"
            class="text-button-underline p-mr-3"
          />
          <Button
            v-else
            @click="() => createDistrictAssessment({ assessmentId: assessment.id, $toast })"
            label="Take Assessment"
            class="text-button-underline p-mr-3"
          />
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="displayAssignHybridAssessmentDialog" class="p-flex-row">
    <h3>Select user to complete self-assessment portion:</h3>
    <div class="p-d-flex" style="justify-content: space-between">
      <Dropdown
        :modelValue="assigningUser"
        :options="assignableUsers"
        :optionLabel="assigningUserLabel"
        @change="(e) => setAssigningUser(e.value)"
        class="half-width-input"
      />
    </div>

    <h3>Select user to complete on-site portion:</h3>
    <div class="p-d-flex" style="justify-content: space-between">
      <Dropdown
        :modelValue="onSiteUser"
        :options="onSiteableUsers"
        :optionLabel="
          (option) => {
            return option.full_name
          }
        "
        @change="(e) => setOnSiteUser(e.value)"
        class="half-width-input"
      />
      <Button
        @click="() => submitAssign()"
        label="Assign"
        class="text-button-underline p-mr-3"
        style="margin-top: -1em"
      />
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from 'primevue/button'
import dayjs from 'dayjs'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'

export default {
  components: {
    Button,
    Dialog,
    Dropdown,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    completedAt() {
      if (this.isReport) return dayjs(this.report?.created_at).format('YYYY-MM-DD')
      return this.assessment?.completed_at
        ? dayjs(this.assessment?.completed_at).format('YYYY-MM-DD')
        : null
    },
  },
  props: {
    assessment: {
      type: Object,
    },
    report: {
      type: Object,
      default: null,
    },
    filterType: {
      type: String,
    },
    isReport: {
      type: Boolean,
      default: false,
    },
    percentageScore: {
      type: String,
      default: '0',
    },
    toggleDownloadDialog: {
      type: Function,
    },
  },
  data() {
    return {
      assigningAssessment: null,
      assignableUsers: [],
      assigningUser: null,
      onSiteableUsers: [],
      onSiteUser: null,
      displayAssignHybridAssessmentDialog: false,
    }
  },
  methods: {
    ...mapActions('assessments', [
      'createDistrictAssessment',
      'assignHybridAssessment',
      'getAssignableUsers',
      'getSuperUsers',
    ]),
    assigningUserLabel(user) {
      return `${user.full_name} (${user.user_profile.district.name})`
    },
    setAssigningUser(user) {
      this.assigningUser = user
    },
    setOnSiteUser(user) {
      this.onSiteUser = user
    },
    async openAssignmentDialog(assessment) {
      this.assigningAssessment = assessment
      this.assignableUsers = await this.getAssignableUsers({
        assessmentId: this.assigningAssessment.id,
        $toast: this.$toast,
      })
      this.onSiteableUsers = await this.getSuperUsers({ $toast: this.$toast })
      this.displayAssignHybridAssessmentDialog = !this.displayAssignHybridAssessmentDialog
    },
    async submitAssign() {
      this.assignHybridAssessment({
        assessmentId: this.assigningAssessment.id,
        assigningUserId: this.assigningUser.id,
        onSiteUserId: this.onSiteUser.id,
        $toast: this.$toast,
      })
      this.displayAssignHybridAssessmentDialog = !this.displayAssignHybridAssessmentDialog

      this.$toast.add({
        severity: 'success',
        group: 'tr',
        summary: 'Hybrid Assessment Assigned!',
        life: 5000,
      })
    },
  },
}
</script>
