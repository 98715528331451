// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../node_modules/primeicons/raw-svg/search.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".chipSelection{font-size:1rem;color:#6b6b6b;background:#e3e3e3;padding:.3rem;border-radius:.3rem;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;margin-top:1rem}.p-autocomplete-input{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:1.5em;background-repeat:no-repeat;background-position:left 10px top 5px;text-indent:35px}", ""]);
// Exports
module.exports = exports;
