<template>
  <div class="p-grid registration-page p-m-6">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../assets/icons/rainbow.png" class="p-mr-3" />
      <h1>Introduction</h1>
    </div>
    <div style="position: relative">
      <p style="color: #495057; max-width: 70%; font-weight: lighter; margin-left: 12px">
        Welcome to SCALE! This Scratch Cooking Assessment and Learning Evaluation is
        <strong>free</strong> for all schools, food service professionals, and partners. Created by
        the <a href="https://www.chefannfoundation.org/who-we-are">Chef Ann Foundation</a>, this
        platform offers digital assessments to help increase scratch cooking. Each assessment will
        generate a customized report that will include linked resources, section scores,
        recommendations, and supporting research. We recommend starting with the District
        Assessment below. <br /><br />
        Recommended browsers: Chrome and Firefox
      </p>
      <img
        src="../assets/dashboard-intro.png"
        width="200"
        height="200"
        style="
          margin-right: 35px;
          box-shadow: 1px 1px 2px black;
          position: absolute;
          top: 0;
          right: 0;
        "
      />
    </div>
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../assets/icons/chart.png" class="p-mr-3" />
      <h1>Dashboard</h1>
    </div>
    <div class="p-col-12">
      <div class="p-col-12 p-d-flex p-jc-end p-ai-center">
        <p class="inline-label p-mr-4">Search:</p>
        <InputText @keyup="search" type="text" v-model="searchValue" />
      </div>
      <DataTable
        v-if="districtAssessments?.data?.length"
        :loading="loading"
        :value="districtAssessments?.data"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :lazy="true"
        ref="dt"
        :totalRecords="totalRecords"
        @page="onPage($event)"
        @sort="onSort($event)"
        filterDisplay="row"
      >
        <!-- This is the district that the user took the assessment for -->
        <Column field="district" header="District" :sortable="true"></Column>
        <Column field="assessment_name" header="Assessment" :sortable="true"></Column>
        <Column field="type" header="Type" :sortable="true"></Column>
        <Column field="created_at" header="Created Date" :sortable="true">
          <template #body="{ data }">
            {{ data.created_at?.split('T')[0] }}
          </template>
        </Column>
        <Column field="updated_at" header="Last Updated" :sortable="true">
          <template #body="{ data }">
            {{ data.updated_at?.split('T')[0] }}
          </template>
        </Column>
        <Column field="completed_at" header="Completed At" :sortable="true">
          <template #body="{ data }">
            {{ data.completed_at?.split('T')[0] || '' }}
          </template>
        </Column>
        <Column field="completed" header="Status" :sortable="true">
          <template #body="{ data }">
            {{ getCompletionStatus(data, currentUser?.role) }}
          </template>
        </Column>
        <Column field="completed" header="Action">
          <template #body="{ data }">
            <div
              v-if="
                data.completed &&
                (currentUser?.role === 'Superuser' ||
                  (!data.assessment.is_reportless_default && data.type !== 'Hybrid'))
              "
            >
              <Button
                @click="$router.push(`/assessments/${data.id}/report`)"
                label="View Report"
                class="text-button-underline"
              />
            </div>
            <div
              v-if="
                !data.completed &&
                (data.type !== 'Hybrid' ||
                  !data.self_assessment_completed ||
                  currentUser?.role === 'Superuser')
              "
            >
              <Button
                @click="$router.push(`/assessments/${data.id}/take`)"
                label="Complete"
                class="text-button-underline"
              />
            </div>
          </template>
        </Column>
        <!-- TODO: link to complete taking assessment or show grade/report link -->
        <!-- <Column field="grade" header="Grade"></Column> -->
      </DataTable>
    </div>

    <div class="p-col-10 p-offset-1 p-mt-5">
      <h2 class="p-ml-2">Recent Assessments</h2>
    </div>

    <div class="p-col-10 p-offset-1 p-d-flex p-flex-wrap p-jc-start p-mt-1">
      <Card
        class="primary-card p-m-2"
        v-for="assessment in assessments?.data"
        :key="assessment.id"
      >
        <template #header>
          <h3>{{ assessment.name }}</h3>
        </template>
        <template #subtitle>
          <div class="p-grid">
            <div class="p-col">Type: {{ assessment.get_type_display }}</div>
          </div>
        </template>
        <template #content>
          <div class="p-grid">
            <div v-html="assessment.quickdescription" class="p-col" />
          </div>
        </template>
        <template #footer>
          <Button
            @click="() => createDistrictAssessment({ assessmentId: assessment.id, $toast })"
            label="Take Assessment"
            class="text-button-underline"
          />
          <!-- <Button
            @click="$router.push(`/assessments/${assessment.id}/report`)"
            label="View Report"
            class="text-button-underline"
          /> -->
          <!-- <i class="pi pi-download"></i> -->
        </template>
      </Card>
    </div>
    <div class="p-col-12 header p-d-flex p-ai-center p-mt-5">
      <img src="../assets/icons/plant.png" class="p-mr-3" />
      <h1>Resources</h1>
    </div>

    <div class="p-col-5">
      <div class="p-col-12 p-d-flex p-jc-between p-ai-start">
        <div>
          <h3>How to use SCALE</h3>
          <p class="resource-description">
            You can start & stop an assessment at any time. Each assessment will provide a unique,
            customized report based on your answers. Districts can use their generated report to
            develop strategic plans or guide program development. Results can be shared with
            internal and external stakeholders to garner support for improvements in school
            nutrition programs as well. By taking assessments annually, you can track your growth
            and progress over time. (For any technical errors or questions, please reach out to
            <a href="mailto:scale@chefannfoundation.org">scale@chefannfoundation.org</a>)
          </p>
        </div>
      </div>
      <div class="p-col-12 p-d-flex p-jc-between p-ai-start">
        <div>
          <h3>Why Scratch Cooking?</h3>
          <p class="resource-description">
            School food has become increasingly more processed and less healthy. With the right
            knowledge and tools, schools can transform their kitchens and lunchrooms to support
            student health, wellbeing, and academic success. With 31 million school lunches served
            every day, there is a huge opportunity to improve nutrition across the country.
          </p>
          <a href="https://www.thelunchbox.org/why-scratch-cooking" style="text-decoration: none">
            <Button label="Learn More" class="text-button-underline" style="margin-top: 10px" />
          </a>
        </div>
      </div>
    </div>
    <div class="p-col-5 p-offset-1">
      <div class="p-col-12 p-d-flex p-jc-between p-ai-start">
        <div>
          <h3>Recipes</h3>
          <p class="resource-description">
            Scratch cooking can be accessible, delicious, and scalable. We’ve created 300+ recipes
            that are generated specifically for school kitchens, tested in cafeterias, and
            kid-approved. Each recipe is analyzed for USDA meal components, and can be easily
            adjusted to any portion quantity.
          </p>
          <a
            href="https://www.thelunchbox.org/recipes-menus/recipes?s=eyJwYWdlIjoxLCJzZWFyY2giOm51bGwsImNhdGVnb3J5IjpudWxsLCJzb3J0IjoibmFtZSIsImZvcm1hdCI6ImNhcmQifQ%3D%3D"
            style="text-decoration: none"
          >
            <Button label="See Recipes" class="text-button-underline"
          /></a>
        </div>
      </div>
      <div class="p-col-12 p-d-flex p-jc-between p-ai-start" style="margin-top: 92px">
        <div>
          <h3>About Us</h3>
          <p class="resource-description">
            The Chef Ann Foundation is a 501(c)(3) non-profit dedicated to promoting
            whole-ingredient, scratch cooking in schools. Scratch cooking enables schools to serve
            the healthiest, tastiest meals so that kids are well-nourished and ready to learn. As a
            501(c)(3) nonprofit, we work with both public and private schools in all 50 states.
            Founded in 2009 by Chef Ann Cooper, we’ve helped more than 13,500 schools and 3.3
            million kids eat healthier, fresher school meals.
          </p>
          <a href="https://www.thelunchbox.org/who-we-are" style="text-decoration: none">
            <Button label="Learn More" class="text-button-underline"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Card from 'primevue/card'
import Button from 'primevue/button'

export default {
  name: 'Dashboard',
  components: {
    DataTable,
    Column,
    InputText,
    Card,
    Button,
  },
  data() {
    return {
      searchValue: '',
      loading: false,
      districtAssessments: {},
      totalRecords: 0,
      assessments: [],
    }
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('assessments', [
      'createDistrictAssessment',
      'getAssessments',
      'getDistrictAssessments',
      'searchAssessments',
    ]),
    getSortDirection(field, direction) {
      let sortField = field

      switch (field) {
        case 'assessment_name':
          sortField = field.replace('_', '__')
          break
        case 'district':
          sortField = 'district__name'
          break
        case 'type':
          sortField = 'assessment__type'
          break
        default:
          break
      }

      return direction > 0 ? sortField : `-${sortField}`
    },
    getCompletionStatus(data, userRole) {
      if (!data.completed && data.self_assessment_completed) {
        return userRole === 'Superuser'
          ? 'Self-Assessment Completed, On-Site Incomplete'
          : 'Completed'
      } else {
        return data.completed ? 'Completed' : 'Incomplete'
      }
    },
    async search(e) {
      this.loading = true
      this.districtAssessments = await this.searchAssessments({
        term: e.target.value,
        $toast: this.$toast,
      })

      if (this.districtAssessments?.meta) {
        this.totalRecords = this.districtAssessments.meta.total
      }

      this.loading = false
    },
    async onPage(e) {
      this.loading = true
      const sort = this.getSortDirection(e.sortField, e.sortOrder)
      this.districtAssessments = await this.getDistrictAssessments({
        page: e.page + 1,
        sortField: sort,
        $toast: this.$toast,
      })
      this.loading = false
    },
    async onSort(e) {
      this.loading = true
      const sort = this.getSortDirection(e.sortField, e.sortOrder)
      this.districtAssessments = await this.getDistrictAssessments({
        page: 1,
        sortField: sort,
        $toast: this.$toast,
      })
      this.loading = false
    },
  },
  async mounted() {
    this.districtAssessments = await this.getDistrictAssessments({ page: 1, $toast: this.$toast })

    if (this.districtAssessments?.meta) {
      this.totalRecords = this.districtAssessments.meta.total
    }

    this.assessments = await this.getAssessments({
      page: 1,
      pageSize: 6,
      sortField: '-created_at',
      type: 'all', // exclude hybrid assessments from recent, since they need to be assigned separately
      $toast: this.$toast,
    })
  },
}
</script>

<style scoped>
.resource-description {
  font-weight: 400;
}
</style>
