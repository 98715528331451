<template>
  <Card class="question-card">
    <template #header>
      <h3 class="category-title p-mt-0">{{ currentQuestionDetails?.question?.text }}</h3>
    </template>
    <template #content>
      <p v-if="currentQuestionDetails?.question?.type === 11">
        <strong class="rollup-question-container">
          {{ currentQuestionDetails?.question?.text }}
          <div
            v-if="!currentQuestionDetails?.question?.rollup_config?.total_choice"
            class="rollup-total"
            :class="{
              'percent-format':
                currentQuestionDetails?.question?.rollup_config?.display_format === 'percent',
            }"
          >
            <InputText disabled="disabled" type="number" />
            <span class="rollup-percent"></span>
          </div>
        </strong>
      </p>

      <p v-html="currentQuestionDetails?.question?.description"></p>
      <div
        class="answer-container"
        :class="{
          comparison: currentQuestionDetails?.question?.type === 12,
        }"
      >
        <template v-for="(ans, i) in currentQuestionDetails?.answers" :key="ans.id">
          <template v-if="currentQuestionDetails?.question?.type === 1">
            <span class="answer-wrapper">
              <RadioButton
                :name="`${ans.id}-answer`"
                v-model="radioAnswer"
                :class="{ 'p-invalid': false }"
                :disabled="true"
              />
              <label :for="`question-${currentQuestionDetails?.question?.id}`">{{
                ans.text
              }}</label>
            </span>
          </template>
          <template v-else-if="currentQuestionDetails?.question?.type === 2">
            <span class="answer-wrapper">
              <RadioButton :name="`${ans.id}-answer`" v-model="radioAnswer" :disabled="true" />
              <label :for="`${ans.id}-answer`">{{ ans.text }}</label>
            </span>
          </template>
          <template v-if="currentQuestionDetails?.question?.type === 3">
            <span class="answer-wrapper">
              <Checkbox
                :binary="true"
                @change="(e) => setCheckbox(ans)"
                name="check-answer"
                :disabled="true"
              />
              <label :for="`${ans.id}-answer`">{{ ans.text }}</label>
            </span>
          </template>
          <template v-else-if="currentQuestionDetails?.question?.type === 4">
            <Slider
              v-model="sliderAnswer"
              :min="1"
              :max="5"
              :class="['full-width-input']"
              style="width: 50%"
            />
            <label :for="`question-${currentQuestionDetails?.question?.id}`">{{ ans.text }}</label>
          </template>
          <InputText
            v-else-if="currentQuestionDetails?.question?.type === 7"
            @input="(e) => $emit('update:answer', { ...ans, value: e.target.value })"
            type="number"
            class="full-width-input"
            :disabled="true"
          />
          <InputText
            v-else-if="currentQuestionDetails?.question?.type === 8"
            @input="(e) => $emit('update:answer', { ...ans, value: e.target.value })"
            type="text"
            :class="['full-width-input']"
            :disabled="true"
          />
          <template v-else-if="currentQuestionDetails?.question?.type === 11">
            <span
              class="answer-wrapper rollup"
              :class="{
                'rollup-total':
                  currentQuestionDetails?.question?.rollup_config?.total_ans === ans.id,
                'percent-format':
                  currentQuestionDetails?.question?.rollup_config?.display_format === 'percent',
              }"
            >
              <label :for="`${ans.id}-answer`">{{ ans.text }}</label>
              <InputText type="number" :name="`${ans.id}-answer`" :disabled="true" />
              <span class="rollup-percent"></span>
            </span>
          </template>
          <template v-else-if="currentQuestionDetails?.question?.type === 12">
            <div
              v-if="currentQuestionDetails?.question?.comparison_config?.operator && i === 1"
              class="comparison-operator"
            >
              <label :for="`${currentQuestionDetails?.question.id}-operator`">
                {{
                  getOperatorDisplay(currentQuestionDetails?.question?.comparison_config?.operator)
                }}
              </label>
            </div>
            <span class="answer-wrapper">
              <label :for="`${ans.id}-answer`">{{ ans.text }}</label>
              <InputText type="number" :name="`${ans.id}-answer`" :disabled="true" />
            </span>
          </template>
        </template>
      </div>

      <template v-if="showResponse">
        <hr class="p-my-5" />

        <h3>RESPONSE:</h3>

        <div v-html="answer.response" />
      </template>
    </template>
  </Card>
</template>

<script>
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Slider from 'primevue/slider'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'

export default {
  components: {
    InputText,
    RadioButton,
    Slider,
    Card,
    Checkbox,
  },
  props: {
    currentQuestionDetails: {
      type: Object,
    },
    isFieldInvalid: {
      type: Function,
    },
    answer: {
      type: Object,
    },
    showResponse: {
      type: Boolean,
    },
  },
  emits: ['update:answer'],
  computed: {
    radioAnswer: {
      get() {
        return this.answer
      },
      set(val) {
        this.$emit('update:answer', val)
      },
    },
    sliderAnswer: {
      get() {
        return this.answer?.value
      },
      set(val) {
        this.$emit('update:answer', val)
      },
    },
  },
  methods: {
    getOperatorDisplay(operator) {
      if (operator === 'Divide') return '/'
      if (operator === 'Multiply') return '*'
      if (operator === 'Add') return '+'
      if (operator === 'Subtract') return '-'
    },
    setCheckbox(ans) {
      // Had to use stringify and parse this.answer.value here because
      // this. $emit was stringifying nested objects and it was causing
      // strange problems

      let values = []

      if (this.answer.value) {
        const parsedAnswer = JSON.parse(this.answer.value)

        const index = parsedAnswer.findIndex((val) => {
          return val.id === ans.id
        })
        if (index !== -1) {
          values = [...parsedAnswer]
          values.splice(index, 1)
        } else {
          values = [...parsedAnswer, ans]
        }
      } else {
        values.push(ans)
      }

      this.$emit('update:answer', { value: JSON.stringify(values) })
    },
  },
}
</script>
