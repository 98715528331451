// import { templateSection } from './createMutations'

export const initialAssessment = {
  setupData: {
    name: '',
    description: '',
    type: null,
    isDefault: false,
    districts: [],
  },
  sections: [],
}

export default {
  selectedDistrict: '',
  assessments: [],
  types: [
    {
      id: 1,
      name: 'On Site',
    },
    {
      id: 2,
      name: 'Self Assessment',
    },
    {
      id: 3,
      name: 'Hybrid',
    },
  ],
  questionTypes: [],
  assessmentDetails: {}, // used for viewing & taking an assessment
  nestedQuestionsList: [],
  currentQuestionDetails: {
    // used for taking an assessment
    queryParams: {
      sec: 1,
      sub: 1,
      q: 1,
    },
  },
  districtAssessments: [],

  // State for creating assessments:
  currentStep: 1,
  currentSubSectionInfo: {
    sectionIndex: 0,
    subIndex: 0,
  },
  currentQuestions: [], // questions and answers that are loaded per subsection
  currentAssessment: {
    // used for creating an assessment
    ...initialAssessment,
  },
  showQuestions: false,
  previewQuestions: [],
}
