<template>
  <Sidebar
    :visible="true"
    :dismissable="false"
    :showCloseIcon="false"
    :modal="false"
    class="app-sidebar"
  >
    <div class="logo-wrapper">
      <router-link to="/">
        <img
          src="https://storage.googleapis.com/chefann-prod-v1/images/SCALE-Logo.png"
          class="login-logo"
        />
      </router-link>
    </div>
    <ul v-if="['Superuser', 'Building Admin'].includes(currentUser?.role)" class="nav-list">
      <li class="nav-item">
        <router-link data-testid="registration" to="/users/registration">
          <i class="pi pi-user"></i>
          Registration
        </router-link>
      </li>
      <li class="nav-item">
        <router-link data-testid="users" to="/users">
          <i class="pi pi-check-circle"></i>
          Approval
        </router-link>
      </li>
      <li class="nav-item">
        <router-link data-testid="assessments" to="/assessments">
          <i class="pi pi-plus-circle"></i>
          Assessments
        </router-link>
      </li>
    </ul>
    <ul v-if="['School User'].includes(currentUser?.role)" class="nav-list">
      <li class="nav-item">
        <router-link data-testid="dashboard" to="/">
          <i class="pi pi-user"></i>
          Dashboard
        </router-link>
      </li>
    </ul>
    <div class="fruitpic">
      <img src="../assets/footer-fruits.png" />
    </div>
    <div class="logo-wrapper2">
      <a href="https://www.chefannfoundation.org/"> <img src="../assets/logo-caf-rev.svg" /> </a>
    </div>
  </Sidebar>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from 'primevue/sidebar'

export default {
  components: {
    Sidebar,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
}
</script>
