export default {
  updateCurrentUser(state, payload) {
    state.currentUser = payload
  },
  updateUsers(state, payload) {
    state.users = payload
  },
  logUserIn(state, { user, authenticated }) {
    state.isAuthenticated = true
    state.currentUser = user
  },
  setCheckingAuth(state, isChecking) {
    state.checkingAuth = isChecking
  },
  clearUsers(state) {
    state.users = []
  },
  clearAllUserData(state) {
    // this is used to reset data for users who log out or whose authentication expires
    state.users = []
    state.currentUser = {}
    state.isAuthenticated = false
    state.checkingAuth = false
  },
}
