<template>
  <div class="p-grid p-col assessments-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../../assets/icons/books.png" class="p-mr-3" />
      <h1>Assessment Report</h1>
    </div>
    <div style="margin-left: 10px">
      <Button
        @click="() => downloadReport('Detailed')"
        class="p-button p-component text-button-underline p-mr-3"
        label="Print Detailed PDF"
      />
      <Button
        @click="() => downloadReport('Summary')"
        class="p-button p-component text-button-underline p-mr-3"
        label="Print Summary PDF"
      />
      <Button
        v-if="currentUser?.role === 'Superuser'"
        @click="() => downloadReport('Admin Detailed')"
        class="p-button p-component text-button-underline p-mr-3"
        label="Print Admin Detailed PDF"
      />
    </div>
    <ReportsComponent :print="this.printParams" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReportsComponent from '@/components/ReportsComponent.vue'
import Button from 'primevue/button'

import { reportName } from '../../utils/pdfHelper.js'

export default {
  components: {
    ReportsComponent,
    Button,
  },
  data() {
    return {
      printParams: null,
    }
  },
  computed: {
    ...mapState('reports', ['assessmentReport']),
    ...mapState('users', ['currentUser']),
  },
  methods: {
    downloadReport(type) {
      if (
        this.assessmentReport?.report?.report_data?.district_assessment !==
        parseInt(this.$route.params.id, 10)
      ) {
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Report Error',
          detail:
            'An associated Report was not found. Please submit the Assessment before generating a report.',
          life: 5000,
        })
      } else if (
        type === 'Detailed' &&
        this.assessmentReport?.report?.report_data?.detailed_report_url
      ) {
        this.downloadReportFile(
          this.assessmentReport.report.report_data.detailed_report_url,
          reportName(this.assessmentReport, type, this.currentUser),
        )
      } else if (
        type === 'Summary' &&
        this.assessmentReport?.report?.report_data?.summary_report_url
      ) {
        this.downloadReportFile(
          this.assessmentReport.report.report_data.summary_report_url,
          reportName(this.assessmentReport, type, this.currentUser),
        )
      } else if (
        type === 'Admin Detailed' &&
        this.assessmentReport?.report?.report_data?.admin_detailed_report_url
      ) {
        this.downloadReportFile(
          this.assessmentReport.report.report_data.admin_detailed_report_url,
          reportName(this.assessmentReport, type, this.currentUser),
        )
      } else {
        this.updatePrintParams(type)
      }
    },
    downloadReportFile(url, filename) {
      fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = filename + '.pdf'
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          a.remove()
        })
    },
    updatePrintParams(type) {
      if (type) {
        this.printParams = type
      } else {
        this.printParams = null
      }
    },
  },
  updated() {
    this.printParams = null
  },
}
</script>
